import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { ApplicationSummary } from "src/models/onboardingApplicants";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  patient: ApplicationSummary | undefined;
}

const PatientSummary = ({ patient }: IProps) => {
  return (
    <Card>
      <CardHeader title="Info" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>
                <Value text={patient?.patientId} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Application Created</TableCell>
              <TableCell>
                <Value dateTime={patient?.createdAt} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>
                <Value
                  text={
                    patient?.status &&
                    convertEnumValueToReadableString(patient?.status, " ")
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Legal Name</TableCell>
              <TableCell>
                <Value text={patient?.legalName} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DOB</TableCell>
              <TableCell>
                <Value date={patient?.dob} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>NHS Number</TableCell>
              <TableCell>
                <Value text={patient?.nhsNumber} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default PatientSummary;
