import { alpha, styled } from "@mui/material/styles";

const FullCalendarStyleContainer = styled("div")(({ theme }) => ({
  "& .fc": {
    "--fc-bg-event-opacity": 1,
    "--fc-border-color": theme.palette.divider,
    "--fc-today-bg-color": alpha(theme.palette.divider, 0.3),
    "--fc-event-bg-color": alpha(theme.palette.success.light, 0.1),
  },
  "& .fc-daygrid-day-number": {
    cursor: "pointer",
  },
  "& .fc-event-main": {
    cursor: "pointer",
    fontSize: theme.typography.subtitle1.fontSize,
    borderWidth: 0,
    display: "flex",
    alignItems: "center",
    paddingLeft: 4,
    backgroundColor: alpha(theme.palette.success.light, 0.1),
    color: alpha(theme.palette.success.dark, 0.8),
    borderRadius: 2,
    overflow: "hidden",
  },
  "& .fc-event-main:hover": {
    backgroundColor: alpha(theme.palette.success.light, 0.2),
  },
  "& .fc-event-title-container": {
    display: "flex",
    alignItems: "center",
  },
  "& .fc-popover": {
    zIndex: 1,
  },
  "& .fc-button": {
    backgroundColor: theme.palette.primary.main,
    border: "none",
    boxShadow: "none !important",
  },
  "& .fc-button:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& .fc-button-primary:not(:disabled).fc-button-active": {
    backgroundColor: theme.palette.action.disabled,
  },
  "& .fc-button:disabled": {
    backgroundColor: theme.palette.action.disabled,
    opacity: 1,
  },
  " .fc-button.fc-button-primary:active": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& .fc-daygrid-day.appts-available": {
    backgroundColor: alpha(theme.palette.success.light, 0.2),
    color: alpha(theme.palette.success.dark, 0.9),
  },
  "& .fc-daygrid-day.appts-available:hover": {
    cursor: "pointer",
    backgroundColor: alpha(theme.palette.success.light, 0.3),
  },
  "& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events": {
    minHeight: "50px",
  },
  "& .fc-toolbar.fc-header-toolbar": {
    marginBottom: 0,
  },
  "& .fc-toolbar-title": {
    fontWeight: theme.typography.body1.fontWeight,
    fontSize: theme.typography.h6.fontSize,
    padding: 10,
  },
  "& .fc .fc-col-header-cell-cushion, .fc-list-day": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    fontSize: theme.typography.overline.fontSize,
    fontWeight: theme.typography.overline.fontWeight,
    letterSpacing: theme.typography.overline.letterSpacing,
    lineHeight: theme.typography.overline.lineHeight,
    textTransform: theme.typography.overline.textTransform,
  },
  "& .fc-timegrid-slot": {
    height: "40px",
  },
  "& .fc .fc-day-other .fc-daygrid-day-top": {
    color: theme.palette.text.primary,
  },
  " & .fc-daygrid-day-events": {
    justifyContent: "center",
    alignItems: "center",
  },
  "& .fc-daygrid-event": {
    borderRadius: theme.shape.borderRadius,
    padding: "0px 4px",
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    lineHeight: theme.typography.subtitle2.lineHeight,
  },
  "& .fc-timegrid-slot-label": {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.body1.fontWeight,
  },
  "& .fc-event-title": {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
  },
  "& .fc-daygrid-block-event .fc-event-time": {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
  },
  "& .fc-daygrid-more-link": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  "& .fc-scrollgrid": {
    borderColor: "transparent",
  },
  "& .fc-scrollgrid td:last-of-type": {
    borderRightColor: "transparent",
  },
  '& [role="row"]:last-of-type td': {
    borderBottomColor: "transparent",
  },
  '& th[role="presentation"]': {
    borderRightColor: "transparent",
  },
  "& .fc-list": {
    borderColor: "transparent",
  },
  "@media (max-width: 600px)": {
    "& .fc-toolbar.fc-header-toolbar": {
      marginBottom: 10,
    },
    "& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events": {
      minHeight: "0px",
    },
    "& .fc-timeGridWeek-button": {
      display: "none",
    },
  },
  "@media (prefers-color-scheme: dark)": {
    "& .fc-daygrid-day.appts-available": {
      color: theme.palette.grey[300],
      backgroundColor: alpha(theme.palette.success.light, 0.3),
    },
    "& .fc-col-header-cell-cushion, .fc-list-day": {
      backgroundColor: "black",
    },
    "& .fc": {
      "--fc-page-bg-color": "transparent",
    },
    "& .fc-event-main": {
      color: theme.palette.grey[300],
      backgroundColor: alpha(theme.palette.success.light, 0.3),
    },
  },
}));

export default FullCalendarStyleContainer;
