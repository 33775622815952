import { Page, SelectInput, useHash } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import { useState } from "react";

import {
  ClinicalReview,
  ClinicalReviewState,
  ClinicalReviewType,
} from "src/models";
import ClinicalReviewsTable from "src/pages/ClinicalReviews/ClinicalReviewsTable";
import { usePaginationSettings } from "src/pagination";

const ClinicalReviews = () => {
  const [state, setState] = useHash<ClinicalReviewState>(
    ClinicalReviewState.PENDING,
  );
  const [type, setType] = useState<ClinicalReviewType | "all">("all");

  const [paginationSettings, { setPageNumber, setPageSize, toggleSort }] =
    usePaginationSettings<ClinicalReview>({
      pageNumber: 0,
      pageSize: 100,
      sortColumn: "stateTimestamp",
      sortDirection: "desc",
    });

  return (
    <TabContext value={state}>
      <Page
        tabs={
          <TabList
            aria-label="Formula menu"
            onChange={(_, value: string) =>
              setState(value as ClinicalReviewState)
            }
          >
            {Object.keys(ClinicalReviewState).map((state) => (
              <Tab key={state} label={state} value={state} />
            ))}
          </TabList>
        }
        title="Clinical Reviews"
      >
        <SelectInput
          label="Type"
          onChange={(newType) => setType(newType as ClinicalReviewType)}
          options={[
            { label: "All", value: "all" },
            ...Object.keys(ClinicalReviewType).map((type) => ({
              value: type,
            })),
          ]}
          value={type}
        />

        <ClinicalReviewsTable
          paginationSettings={paginationSettings}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          state={state}
          toggleSort={toggleSort}
          type={type !== "all" ? type : undefined}
        />
      </Page>
    </TabContext>
  );
};

export default ClinicalReviews;
