import {
  cz,
  FormLinks,
  Page,
  SubmitButton,
  TextField,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { ClinicalReviewState } from "src/models";
import {
  useEditClinicalReviewStateMutation,
  useClinicalReviewQuery,
} from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

const FormSchema = z.object({
  reason: cz.field(z.string()),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  clinicalReviewId: string;
  state: ClinicalReviewState;
}

const EditClinicalReviewState = ({ clinicalReviewId, state }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: clinicalReview } = useClinicalReviewQuery(clinicalReviewId);
  const { mutateAsync: edit } =
    useEditClinicalReviewStateMutation(clinicalReviewId);
  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues: { reason: "" },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        reason: data.reason,
        state,
      });
      addToast("Clinical review updated", "success");
      setLocation(`/clinical-reviews/${clinicalReviewId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  let label = "Closed";
  if (state === ClinicalReviewState.CANCELLED) {
    label = "Cancelled";
  }

  return (
    <Page
      breadcrumbs={[
        { to: "/clinical-reviews/", label: "Clinical Reviews" },
        {
          to: `/clinical-reviews/${clinicalReviewId}/`,
          label: `${clinicalReview?.patientName} - ${convertEnumValueToReadableString(clinicalReview?.type ?? "", " ")}`,
        },
      ]}
      disablePadding
      title="Update Clinical Review"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardContent>
            <TextField
              fullWidth
              helperText={`Please explain why this clinicalReview should be ${label}`}
              label="Reason for change"
              name="reason"
              autoComplete="off"
              required
            />
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label={label} />
            <FormLinks
              links={[
                { label: "Back", to: `/clinicalReviews/${clinicalReviewId}/` },
              ]}
            />
          </CardActions>
        </form>
      </FormProvider>
    </Page>
  );
};

export default EditClinicalReviewState;
