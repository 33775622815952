import { z } from "zod";

export enum PartnerUserRole {
  DEVELOPER = "DEVELOPER",
  ONBOARDING_CLERK = "ONBOARDING_CLERK",
}

const partnerUserSchema = z.object({
  id: z.number().int().positive(),
  activeSessions: z.number().int(),
  created: z.coerce.date(),
  disabled: z.coerce.date().nullable(),
  email: z.string(),
  expires: z.coerce.date().nullable(),
  lastLogin: z.coerce.date().nullable(),
  mfaEnabled: z.boolean(),
  roles: z.array(z.nativeEnum(PartnerUserRole)),
});

export type PartnerUser = z.infer<typeof partnerUserSchema>;

export const newPartnerUser = (data: unknown): PartnerUser =>
  partnerUserSchema.parse(data);

const partnerUserRolesSchema = z.object({
  id: z.coerce.number().int().positive(),
  partnerUserId: z.coerce.number().int().positive(),
  activeFrom: z.coerce.date(),
  expires: z.coerce.date(),
  roles: z.array(z.nativeEnum(PartnerUserRole)),
  grantedBy: z.coerce.number().int().positive(),
  grantedByEmail: z.string(),
  reason: z.string(),
});

export type PartnerUserRoles = z.infer<typeof partnerUserRolesSchema>;

export const newPartnerUserRoles = (data: unknown): PartnerUserRoles =>
  partnerUserRolesSchema.parse(data);
