import { Page, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Menu from "@mui/material/Menu";
import Tab from "@mui/material/Tab";
import { useState } from "react";

import PatientApplicationDetails from "src/pages/OnboardingApplication/PatientApplicationDetails";
import PatientGpDetails from "src/pages/OnboardingApplication/PatientGpDetails";
import PatientInfo from "src/pages/OnboardingApplication/PatientInfo";
import PatientMedicalDetails from "src/pages/OnboardingApplication/PatientMedicalDetails";
import ContactDetails from "src/pages/OnboardingApplication/SummaryPage/ContactDetails";
import GpContactDetails from "src/pages/OnboardingApplication/SummaryPage/GpContactDetails";
import MedicalDetails from "src/pages/OnboardingApplication/SummaryPage/MedicalDetails";
import PatientAgreements from "src/pages/OnboardingApplication/SummaryPage/PatientAgreements";
import PatientSummary from "src/pages/OnboardingApplication/SummaryPage/PatientSummary";
import { useApplicationSummaryQuery } from "src/queries";

interface IProps {
  patientId: string;
}

type TabState =
  | "summary"
  | "patientInfo"
  | "patientGpDetails"
  | "patientMedicalDetails"
  | "otherDetails";

const OnboardingApplication = ({ patientId }: IProps) => {
  const [tab, setTab] = useHash<TabState>("summary");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: application } = useApplicationSummaryQuery(patientId);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={open}
      ></Menu>
      <TabContext value={tab}>
        <Page
          actions={
            <Button
              endIcon={<KeyboardArrowDownIcon />}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              variant="contained"
            >
              Actions
            </Button>
          }
          breadcrumbs={[
            { label: "Onboarding", to: "/onboarding-applicants/" },
            { label: `${application?.legalName}` },
          ]}
          tabs={
            <TabList onChange={(_, value: string) => setTab(value as TabState)}>
              <Tab label="Summary" value="summary" />
              <Tab label="Patient Info" value="patientInfo" />
              <Tab label="Gp Details" value="patientGpDetails" />
              <Tab label="Medical Details" value="patientMedicalDetails" />
              <Tab label="Other Details" value="otherDetails" />
            </TabList>
          }
          title={`Application - ${application?.legalName ?? ""}`}
        >
          <TabPanel value="summary">
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <PatientSummary patient={application} />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <MedicalDetails patient={application} />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <ContactDetails patient={application} />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <GpContactDetails patient={application} />
              </Grid>
              <Grid size={12}>
                <PatientAgreements patientId={patientId} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="patientInfo">
            <PatientInfo patientId={patientId} />
          </TabPanel>
          <TabPanel value="patientGpDetails">
            <PatientGpDetails patientId={patientId} />
          </TabPanel>
          <TabPanel value="patientMedicalDetails">
            <PatientMedicalDetails patientId={patientId} />
          </TabPanel>
          <TabPanel value="otherDetails">
            <PatientApplicationDetails patientId={patientId} />
          </TabPanel>
        </Page>
      </TabContext>
    </>
  );
};

export default OnboardingApplication;
