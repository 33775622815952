import axios from "axios";

import {
  PatientApplicationDetails,
  newPatientApplicationDetails,
} from "src/models";
import { keys } from "src/queries/keys";
import { useQuery } from "src/query";

export const usePatientApplicationDetailsQuery = (patientId: string) =>
  useQuery<PatientApplicationDetails>({
    queryKey: keys.patientApplicationDetails(patientId),
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/application-details/patient/${patientId}/`,
        { signal },
      );
      return newPatientApplicationDetails(response.data);
    },
  });
