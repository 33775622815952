import { Value } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useClinicalReviewQuery } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  clinicalReviewId: string;
}

const ClinicalReviewDetails = ({ clinicalReviewId }: IProps) => {
  const { data: review } = useClinicalReviewQuery(clinicalReviewId);

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Patient</TableCell>
            <TableCell>
              <Value
                link={{
                  label: review?.patientName ?? "",
                  to: `/patients/${review?.patientId}/`,
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Assigned Consultant</TableCell>
            <TableCell>
              {review?.assignedConsultantId && review.assignedConsultantName ? (
                <Value
                  link={{
                    label: review.assignedConsultantName,
                    to: `/clinicians/${review.assignedConsultantId}/`,
                  }}
                />
              ) : (
                <Value text="Not Assigned" />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Requested Review Type</TableCell>
            <TableCell>
              <Value
                text={convertEnumValueToReadableString(review?.type ?? "", " ")}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>State</TableCell>
            <TableCell>
              <Value text={review?.state} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClinicalReviewDetails;
