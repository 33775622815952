import {
  ClinicLogo,
  PageWrapper as CuraPageWrapper,
} from "@curaleaf-international/components";
import AppsIcon from "@mui/icons-material/Apps";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FlightIcon from "@mui/icons-material/Flight";
import GroupsIcon from "@mui/icons-material/Groups";
import Groups3Icon from "@mui/icons-material/Groups3";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HandymanIcon from "@mui/icons-material/Handyman";
import HelpIcon from "@mui/icons-material/Help";
import InventoryIcon from "@mui/icons-material/Inventory";
import MedicationIcon from "@mui/icons-material/Medication";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SummarizeIcon from "@mui/icons-material/Summarize";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import axios from "axios";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import IconLogo from "src/components/IconLogo";
import RoleExpiryBanner from "src/components/RoleExpiryBanner";
import { Role } from "src/models";
import { useMutation } from "src/query";

interface IProps {
  children: React.ReactNode;
}

const PageWrapper = ({ children }: IProps) => {
  const { member, unauthenticate } = useContext(AuthContext);
  const { mutateAsync: logout } = useMutation({
    mutationFn: async () => {
      await axios.delete("/v1/staff-sessions/");
    },
    onSuccess: () => unauthenticate(),
  });

  const links = [
    {
      icon: <CalendarMonthIcon />,
      label: "Appointments",
      to: "/appointments/",
    },
  ];
  if (
    member?.roles.some((role) =>
      [
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.PHARMACIST,
        Role.GENERAL_PRACTITIONER,
        Role.CONSULTANT,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <RemoveRedEyeIcon />,
      label: "Clinical Reviews",
      to: "/clinical-reviews/",
    });
  }

  if (
    member?.roles.some((role) =>
      [
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.FINANCE,
        Role.CUSTOMER_SERVICE_LEAD,
        Role.MEDICAL_SECRETARY_LEAD,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <PaymentsIcon />,
      label: "Appointment Payments",
      to: "/appointment-payments/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.CUSTOMER_SERVICE,
        Role.CUSTOMER_SERVICE_LEAD,
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.STAFF_MANAGER,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <PeopleAltIcon />,
      label: "Patients",
      to: "/patients/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.ONBOARDING,
        Role.ONBOARDING_LEAD,
        Role.CONSULTANT,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.GENERAL_PRACTITIONER,
        Role.CUSTOMER_SERVICE_LEAD,
        Role.CUSTOMER_SERVICE,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <PersonAddAlt1Icon />,
      label: "Patient Onboarding",
      to: "/onboarding-applicants/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <MedicationIcon />,
      label: "Prescribers",
      to: "/prescribers/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.STAFF_MANAGER,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <Groups3Icon />,
      label: "Clinicians",
      to: "/clinicians/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.REPORTER,
        Role.GENERAL_PRACTITIONER,
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.STAFF_MANAGER,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.MEDICAL_SECRETARY,
        Role.CUSTOMER_SERVICE,
        Role.CUSTOMER_SERVICE_LEAD,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <VaccinesIcon />,
      label: "Formulary",
      to: "/formulas/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.REPORTER,
        Role.GENERAL_PRACTITIONER,
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.STAFF_MANAGER,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.MEDICAL_SECRETARY,
        Role.CUSTOMER_SERVICE,
        Role.CUSTOMER_SERVICE_LEAD,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <AppsIcon />,
      label: "Speciality Groups",
      to: "/speciality-groups/",
    });
  }
  links.push({
    icon: <WorkspacesIcon />,
    label: "Clinical Alternative Groups",
    to: "/product-groups/",
  });
  links.push({
    icon: <InventoryIcon />,
    label: "Out of Stock Listings",
    to: "/out-of-stock-listings/",
  });
  if (
    member?.roles.some((role) =>
      [
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.STAFF_MANAGER,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
        Role.CUSTOMER_SERVICE,
        Role.CUSTOMER_SERVICE_LEAD,
        Role.REPORTER,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <GroupsIcon />,
      label: "Staff",
      to: "/staff/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.STAFF_MANAGER,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
        Role.CUSTOMER_SERVICE,
        Role.CUSTOMER_SERVICE_LEAD,
        Role.REPORTER,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <HandshakeIcon />,
      label: "Partner Users",
      to: "/partner-users/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.CUSTOMER_SERVICE,
        Role.CUSTOMER_SERVICE_LEAD,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <ReceiptLongIcon />,
      label: "FP10 Log",
      to: "/fp10s/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.PHARMACIST,
        Role.PHARMACIST_TECHNICIAN,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.CUSTOMER_SERVICE,
        Role.CUSTOMER_SERVICE_LEAD,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
        Role.CONSULTANT,
        Role.GENERAL_PRACTITIONER,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <FlightIcon />,
      label: "Travel Letters",
      to: "/travel-letters/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.PHARMACIST,
        Role.CUSTOMER_SERVICE_LEAD,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
        Role.REPORTER,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <SummarizeIcon />,
      label: "Reports",
      to: "/reports/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.PHARMACIST,
        Role.CUSTOMER_SERVICE_LEAD,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
        Role.REPORTER,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <CreditCardIcon />,
      label: "Subscriptions",
      to: "/subscriptions/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        Role.CUSTOMER_SERVICE_LEAD,
        Role.DEVELOPER,
        Role.HEAD_OF_CLINIC,
        Role.PHARMACIST,
        Role.MEDICAL_SECRETARY,
        Role.MEDICAL_SECRETARY_LEAD,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <HandymanIcon />,
      label: "Tools",
      to: "/tools/",
    });
  }

  links.push({
    icon: <HelpIcon />,
    label: "Help",
    to: "/help/",
  });

  return (
    <CuraPageWrapper
      authenticated={member !== null && member !== undefined}
      banner={<RoleExpiryBanner />}
      children={children}
      links={links}
      logos={{
        large: <ClinicLogo color="currentColor" height={36} />,
        small: <IconLogo color="currentColor" height={20} />,
      }}
      logout={logout}
      memberEmail={member?.email}
    />
  );
};

export default PageWrapper;
