import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const PatientAppHelp = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom sx={{ marginBottom: 4 }}>
          Patient App FAQs
        </Typography>
        <Stack spacing={2}>
          <Typography variant="h6" gutterBottom>
            My verification email link has expired. How do I get another one?
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
            If the verification email link has expired, the patient can request
            a new one by clicking the 'Resend verification email' button in
            their account. It'll be what they see when they log in to their
            account and at this stage in their application, it's the only thing
            they can do besides logging out.
          </Typography>
          <Divider />
          <Typography variant="h6" gutterBottom>
            My reset password link has expired. How do I get another one?
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
            Patients can request a reset password link on the{" "}
            <Link
              href="https://app.curaleafclinic.com/sign-in/"
              underline="always"
            >
              sign in page
            </Link>{" "}
            by clicking the 'Forgotten password?' link just beneath where they
            would enter their email and password.
          </Typography>
          <Divider />
          <Typography variant="h6" gutterBottom>
            Why do I get a blank screen when trying to answer questionnaires?
          </Typography>
          <Typography variant="body1" gutterBottom>
            The most common reason that patients experience these issues is that
            they are using a VPN when trying to complete questionnaires. Please
            ask if the patient is using a VPN and, if so, if they can
            temporarily disable their VPN and try again. If they are not using a
            VPN or the issue persists, please request that they clear
            cookies/cache and try again and/or try using a different browser or
            device.
          </Typography>
          <Divider />
          <Typography variant="h6" gutterBottom>
            Why do I keep getting an error message saying "Unable to create an
            account. Please try signing in or try signing up again later." when
            trying to register?
          </Typography>
          <Typography>
            The majority of the time this is because the patient already has an
            account using the same email address they're trying to register
            with. Please advise them to try signing in instead and if they
            cannot remember their password they can reset their password via the{" "}
            <Link
              href="https://app.curaleafclinic.com/sign-in/"
              underline="always"
            >
              sign in page
            </Link>{" "}
            by clicking the 'Forgotten password?' link just beneath where they
            would enter their email and password.
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default PatientAppHelp;
