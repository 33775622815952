import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { ApplicationSummary } from "src/models/onboardingApplicants";

interface IProps {
  patient: ApplicationSummary | undefined;
}

const GpContactDetails = ({ patient }: IProps) => {
  return (
    <Card>
      <CardHeader title="GP Contact Details" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>GP Name</TableCell>
              <TableCell>
                <Value text={patient?.gpName} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GP Email</TableCell>
              <TableCell>
                <Value text={patient?.gpEmail} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GP Phone Number</TableCell>
              <TableCell>
                <Value text={patient?.gpPhoneNumber} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GP Address</TableCell>
              <TableCell>
                <Value
                  text={
                    patient?.gpAddressLines && patient.gpAddressLines.join(", ")
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GP Postcode</TableCell>
              <TableCell>
                <Value text={patient?.gpPostcode} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default GpContactDetails;
