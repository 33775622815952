import { SkeletonRow, Value } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState, MouseEvent } from "react";
import { Link as WLink } from "wouter";

import Products from "src/components/Products";
import { OutOfStockListing } from "src/models";
import { useOutOfStockListingsQuery } from "src/queries";
import { SX_WARNING } from "src/utils";

interface IProps {
  listing: OutOfStockListing;
}

const OutOfStockListingTableRow = ({ listing }: IProps) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const { data: listings } = useOutOfStockListingsQuery(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!listings) return <SkeletonRow cols={5} />;
  const availableAlternativeCount =
    listing.alternativeFirestoreFormulaIds.filter(
      (firestoreFormulaId) =>
        !listings.some((l) => l.firestoreFormulaId === firestoreFormulaId),
    ).length;

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/out-of-stock-listings/${listing.id}/`}
        >
          View
        </MenuItem>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/out-of-stock-listings/${listing.id}/edit/`}
          disabled={listing.endedOn !== null}
        >
          Edit
        </MenuItem>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/out-of-stock-listings/${listing.id}/close/`}
          disabled={listing.endedOn !== null}
        >
          Mark in stock
        </MenuItem>
      </Menu>
      <TableRow
        key={listing.id}
        sx={availableAlternativeCount < 2 ? SX_WARNING : null}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenCollapse(!openCollapse)}
          >
            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Value
            link={{
              label: listing.productName,
              to: `/out-of-stock-listings/${listing.id}/`,
            }}
          />
        </TableCell>
        <TableCell>
          <Value dateTime={listing.createdOn} />
        </TableCell>
        <TableCell>
          <Value
            link={{
              label: listing.createdByEmail,
              to: `/staff/${listing.createdBy}/`,
            }}
          />
        </TableCell>
        <TableCell>
          {listing.endedOn ? <Value dateTime={listing.endedOn} /> : "ACTIVE"}
        </TableCell>
        <TableCell>
          {listing.endedBy && listing.endedByEmail ? (
            <Value
              link={{
                label: listing.endedByEmail,
                to: `/staff/${listing.endedBy}/`,
              }}
            />
          ) : null}
        </TableCell>
        <TableCell>
          <Value text={listing.patientInformation ? "Yes" : "No"} />
        </TableCell>
        <TableCell>
          <Value
            text={listing.alternativeFirestoreFormulaIds.length.toString()}
          />
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box sx={{ padding: 2 }}>
              <Products
                firestoreFormulaIds={listing.alternativeFirestoreFormulaIds}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OutOfStockListingTableRow;
