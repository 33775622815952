import * as z from "zod";

const patientGpDetailsSchema = z.object({
  id: z.string().uuid(),
  patientId: z.string().uuid(),
  name: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    )
    .nullable(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  addressLines: z.array(z.string()),
  postcode: z.string().nullable(),
});

export type PatientGpDetails = z.infer<typeof patientGpDetailsSchema>;

export const newPatientGpDetails = (data: unknown): PatientGpDetails =>
  patientGpDetailsSchema.parse(data);
