import { formatDate } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useProductGroupQuery } from "src/queries";

interface IProps {
  productGroupId: string | undefined;
}

const ProductGroupDetails = ({ productGroupId }: IProps) => {
  const { data: group } = useProductGroupQuery(productGroupId);

  if (!group) {
    return <Skeleton height="80px" />;
  }

  return (
    <>
      <Card>
        <CardHeader title="Product group" />
        <Divider />

        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{group?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Product type</TableCell>
                <TableCell>{group?.productType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Product count</TableCell>
                <TableCell>{group?.firestoreFormulaIds.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Active prescription count</TableCell>
                <TableCell>{group?.prescriptionCount}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Created</TableCell>
                <TableCell>
                  {`${formatDate(group.createdOn)} by ${group?.createdByEmail}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Updated</TableCell>
                <TableCell>
                  {group.lastUpdated
                    ? `${formatDate(group.lastUpdated)} by ${
                        group.lastUpdatedByEmail
                      }`
                    : "Never"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Discontinued</TableCell>
                <TableCell>
                  {group?.discontinued
                    ? `${formatDate(group.discontinued)} by ${
                        group.discontinuedByEmail
                      }`
                    : "No"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Discontinued reason</TableCell>
                <TableCell>
                  {group?.discontinuedReason ? group.discontinuedReason : null}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default ProductGroupDetails;
