import { ToastContext, Title } from "@curaleaf-international/components";
import { useContext } from "react";
import { useLocation } from "wouter";

import ProductGroupForm from "src/components/ProductGroupForm";
import type { IProductGroupData } from "src/queries";
import { useCreateProductGroupMutation } from "src/queries";

const CreateProductGroup = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createProductGroup } = useCreateProductGroupMutation();

  const onSubmit = async (data: IProductGroupData) => {
    try {
      await createProductGroup(data);
      setLocation("/product-groups/");
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <>
      <Title
        title="Create a Product Group"
        breadcrumbs={[
          { label: "Product Groups", to: "/product-groups/" },
          { label: `Create Product Group` },
        ]}
      />
      <ProductGroupForm
        initialValues={{
          name: "",
          productType: "",
          firestoreFormulaIds: [],
        }}
        back="/product-groups/"
        label="Create"
        onSubmit={onSubmit}
      />
    </>
  );
};

export default CreateProductGroup;
