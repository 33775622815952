import {
  formatDate,
  formatDateTime,
  Value,
} from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { ApplicationSummary } from "src/models";

interface IProps {
  application: ApplicationSummary;
}
const OnboardingRow = ({ application }: IProps) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <Link
            component={WLink}
            href={`/onboarding/${application.patientId}/`}
          >
            {application.patientId}
          </Link>
        </TableCell>
        <TableCell>{application.dob && formatDate(application.dob)}</TableCell>
        <TableCell>{formatDateTime(application.createdAt)}</TableCell>
        <TableCell>{application.legalName}</TableCell>
        <TableCell>{application.postcode}</TableCell>
        <TableCell>
          <Value text={application.selfReportedConditions?.join(", ")} />
        </TableCell>
        <TableCell>{application.status}</TableCell>
      </TableRow>
    </>
  );
};

export default OnboardingRow;
