import { keepPreviousData } from "@tanstack/react-query";
import axios from "axios";
import { formatISO } from "date-fns";

import {
  ApplicationSummary,
  newApplicationSummary,
} from "src/models/onboardingApplicants";
import { PaginatedResult, PaginationSettings } from "src/pagination";
import { keys } from "src/queries/keys";
import { useQuery } from "src/query";

export const useApplicationSummariesQuery = (
  dateOfBirth: Date | null,
  pagination?: Partial<PaginationSettings<ApplicationSummary>>,
) => {
  const { rawSearchQuery: _ = "", ...params } = pagination ?? {};
  return useQuery<PaginatedResult<ApplicationSummary>>({
    queryKey: keys.applicationSummaries(pagination, dateOfBirth),
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/onboarding/", {
        params: {
          ...params,
          dateOfBirth: dateOfBirth ? formatISO(dateOfBirth) : null,
          signal,
        },
      });
      return {
        rows: response.data.applications.map((data: unknown) =>
          newApplicationSummary(data),
        ),
        totalRecordCount: response.data.totalRecordCount,
        paginationSettings: response.data.paginationSettings,
      };
    },
    placeholderData: keepPreviousData,
  });
};

export const useApplicationSummaryQuery = (patientId: string) =>
  useQuery<ApplicationSummary>({
    queryKey: keys.applicationSummary(patientId),
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/onboarding/patient/${patientId}/`, {
        signal,
      });
      return newApplicationSummary(response.data);
    },
  });
