import "@fontsource/gothic-a1/400.css";
import "@fontsource/gothic-a1/500.css";
import "@fontsource/gothic-a1/600.css";
import "@fontsource/gothic-a1/700.css";
import {
  ToastContextProvider,
  Toasts,
} from "@curaleaf-international/components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { AuthContextProvider } from "src/AuthContext";
import Router from "src/Router";
import ThemeProvider from "src/ThemeProvider";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <HelmetProvider>
          <Helmet>
            <title>Curaleaf Clinic</title>
          </Helmet>
          <ThemeProvider>
            <ToastContextProvider>
              <Toasts />
              <Router />
            </ToastContextProvider>
          </ThemeProvider>
        </HelmetProvider>
      </AuthContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
