import { ToastContext, Title } from "@curaleaf-international/components";
import { useContext } from "react";
import { useLocation } from "wouter";

import OutOfStockListingForm, {
  ValidatedType,
} from "src/components/OutOfStockListingForm";
import { useCreateOutOfStockListingMutation } from "src/queries";

const CreateOutOfStockListing = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createOutOfStockListing } =
    useCreateOutOfStockListingMutation();

  const onSubmit = async (data: ValidatedType) => {
    try {
      await createOutOfStockListing({
        ...data,
        alternativeFirestoreFormulaIds: data.alternativeFirestoreFormulaIds.map(
          (formula) => formula.id,
        ),
      });
      setLocation("/out-of-stock-listings/");
      addToast("Out of stock listing created", "success");
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <>
      <Title
        title="Create Out of Stock Listing"
        breadcrumbs={[
          { label: "Out of Stock Listings", to: "/out-of-stock-listings/" },
          { label: "Create Out of Stock Listing" },
        ]}
      />
      <OutOfStockListingForm
        initialValues={{
          firestoreFormulaId: "",
          alternativeFirestoreFormulaIds: [],
          patientInformation: "",
          internalInformation: "",
        }}
        back="/out-of-stock-listings/"
        label="Create"
        onSubmit={onSubmit}
      />
    </>
  );
};

export default CreateOutOfStockListing;
