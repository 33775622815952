import { SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { ApplicationSummary } from "src/models/onboardingApplicants";
import OnboardingRow from "src/pages/Onboarding/OnboardingRow";
import { PaginationSettings } from "src/pagination";
import { useApplicationSummariesQuery } from "src/queries";

interface IProps {
  paginationSettings: PaginationSettings<ApplicationSummary>;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  toggleSort: (columnName: keyof ApplicationSummary) => void;
  dateOfBirth: Date | null;
}

const OnboardingTable = ({
  paginationSettings,
  setPageNumber,
  setPageSize,
  toggleSort,
  dateOfBirth,
}: IProps) => {
  const { data } = useApplicationSummariesQuery(
    dateOfBirth,
    paginationSettings,
  );
  const { rows: applications, totalRecordCount } = data || {};
  let rows = null;
  if (!applications) {
    rows = <SkeletonRow cols={7} />;
  } else {
    rows = (
      <TableRow>
        <TableCell align="center" colSpan={7}>
          No applications found.
        </TableCell>
      </TableRow>
    );
    rows = applications.map((application) => (
      <OnboardingRow key={application.patientId} application={application} />
    ));
  }

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>DOB</TableCell>
            <TableCell>
              <TableSortLabel<ApplicationSummary>
                columnName={"createdAt"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                Application Created
              </TableSortLabel>
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Postcode</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      <TablePagination<ApplicationSummary>
        recordCount={totalRecordCount}
        paginationSettings={paginationSettings}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        rowsPerPageOptions={[100, 500, 750, 1000]}
      />
    </TableContainer>
  );
};

export default OnboardingTable;
