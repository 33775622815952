import {
  formatDateTime,
  Page,
  ToastContext,
  FormLinks,
  Value,
} from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { useLocation } from "wouter";

import {
  useToggleDisabledPartnerUserMutation,
  usePartnerUserQuery,
} from "src/queries";

interface IProps {
  partnerUserId: number;
}

const DisablePartnerUser = ({ partnerUserId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: partnerUser } = usePartnerUserQuery(partnerUserId);
  const { mutateAsync: toggleDisablePartnerUser, isPending } =
    useToggleDisabledPartnerUserMutation(partnerUserId);

  const onClick = async () => {
    try {
      await toggleDisablePartnerUser({
        disabled: partnerUser!.disabled === null,
      });
      addToast(
        `Partner User ${partnerUser!.disabled === null ? "disabled" : "enabled"}`,
        "success",
      );
      setLocation("/partner-users/");
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <Page
      title="Disable Staff Member"
      breadcrumbs={[
        { to: "/staff", label: "Staff" },
        {
          label: `${partnerUser?.email ?? ""}`,
          to: `/staff/${partnerUser?.id ?? ""}/`,
        },
        { label: "Disable Staff Member" },
      ]}
    >
      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    <Value text={partnerUser?.email} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Disabled</TableCell>
                  <TableCell>
                    {partnerUser?.disabled ? (
                      <Value dateTime={partnerUser.disabled} />
                    ) : (
                      "No"
                    )}
                    {partnerUser?.disabled !== undefined &&
                    partnerUser?.disabled !== null
                      ? formatDateTime(partnerUser.disabled)
                      : "No"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            color="primary"
            disabled={partnerUser === undefined}
            loading={isPending}
            onClick={onClick}
            variant="contained"
          >
            {partnerUser?.disabled === null ? "Disable" : "Enable"}
          </LoadingButton>
          <FormLinks
            links={[{ label: "Back", to: `/partner-users/${partnerUserId}/` }]}
          />
        </CardActions>
      </Card>
    </Page>
  );
};

export default DisablePartnerUser;
