import { formatDate, SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { PatientAgreement } from "src/models/patientAgreements";
import { usePatientAgreementsQuery } from "src/queries/patientAgreements";

interface IProps {
  patientId: string;
}

const PatientAgreements = ({ patientId }: IProps) => {
  const { data: agreements } = usePatientAgreementsQuery(patientId);

  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardHeader title="Patient Agreements" />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agreements ? (
              agreements.map((agreement: PatientAgreement) => (
                <TableRow key={agreement.id}>
                  <TableCell>{agreement.agreementType}</TableCell>
                  <TableCell>{agreement.version}</TableCell>
                  <TableCell>{formatDate(agreement.timestamp)}</TableCell>
                </TableRow>
              ))
            ) : (
              <SkeletonRow cols={3} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
export default PatientAgreements;
