import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReactNode } from "react";

import PartnerUserSessionRow from "src/pages/PartnerUser/PartnerUserSessionRow";
import { usePartnerUserSessionsQuery } from "src/queries";

interface IProps {
  partnerUserId: number;
}

const PartnerUserSessions = ({ partnerUserId }: IProps) => {
  const { data } = usePartnerUserSessionsQuery(partnerUserId);
  let sessions: ReactNode | ReactNode[] = <SkeletonRow cols={5} />;
  if (data !== undefined && data.length > 0) {
    sessions = data
      ?.sort(sortByKey((session) => [session.start], "desc"))
      .map((session) => (
        <PartnerUserSessionRow key={session.id} session={session} />
      ));
  } else {
    sessions = (
      <TableRow>
        <TableCell colSpan={5}>No sessions found</TableCell>
      </TableRow>
    );
  }
  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>Stop</TableCell>
              <TableCell>Remote Address</TableCell>
              <TableCell>System</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{sessions}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PartnerUserSessions;
