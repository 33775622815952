import {
  formatDate,
  sortByKey,
  SkeletonRow,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";

import { FirestoreFormula } from "src/models";
import { useProductsQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "name" | "outOfStock" | "discontinued";

interface IProps {
  firestoreFormulaIds: string[];
}

const Products = ({ firestoreFormulaIds }: IProps) => {
  const [sortDirection, setSortDirection] = useState<Direction>("asc");
  const [sortBy, setSortBy] = useState<OrderableProperties>("name");
  const { data: products } = useProductsQuery(false);
  const filteredProducts = products?.filter((p: FirestoreFormula) =>
    firestoreFormulaIds.includes(p.id),
  );

  const sortKey = (product: FirestoreFormula) => [product[sortBy]];
  const sortedProducts = filteredProducts?.sort(
    sortByKey(sortKey, sortDirection),
  );

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = sortBy === property && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "name"}
                  direction={sortDirection}
                  onClick={onSortClick("name")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "outOfStock"}
                  direction={sortDirection}
                  onClick={onSortClick("outOfStock")}
                >
                  Out of stock
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "discontinued"}
                  direction={sortDirection}
                  onClick={onSortClick("discontinued")}
                >
                  Discontinued
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProducts?.map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.outOfStock ? "Yes" : "No"}</TableCell>
                <TableCell>
                  {product.discontinued
                    ? `${formatDate(product.discontinued)} by ${
                        product.discontinuedBy
                      }`
                    : "No"}
                </TableCell>
              </TableRow>
            )) ?? <SkeletonRow cols={4} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Products;
