import {
  zodDecimal,
  FormLinks,
  MonetaryField,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  Value,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Decimal } from "decimal.js";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import {
  useAppointmentQuery,
  useAlterAppointmentPriceMutation,
} from "src/queries";

const FormSchema = z.object({
  price: zodDecimal(),
  reason: z.string().min(1),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  appointmentId: string;
}

const AlterAppointmentPrice = ({ appointmentId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: appointment } = useAppointmentQuery(appointmentId);
  const { mutateAsync: alterAppointment } =
    useAlterAppointmentPriceMutation(appointmentId);

  const defaultValues = {
    price: appointment?.price ?? new Decimal(0),
    reason: "",
  };

  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues,
    resolver: zodResolver(FormSchema as any),
  });

  useEffect(() => methods.reset(defaultValues), [appointment]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await alterAppointment({
        price: data.price,
        reason: data.reason,
      });
      setLocation(`/appointments/${appointmentId}/`);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "FREE_APPOINTMENT"
      ) {
        addToast("Cannot alter price on free appointment", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "MUST_REDUCE"
      ) {
        addToast("New price must be lower than original price.", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "OVER_4_MONTHS_OLD"
      ) {
        addToast("The appointment is over 4 months old.", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "PAID_FOR_WITH_APPOINTMENT_CREDIT"
      ) {
        addToast(
          "Cannot alter the price on an appointment paid for using an appointment credit.",
          "error",
        );
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          { label: "Appointments", to: "/appointments/" },
          {
            label: `Appointment for ${appointment?.patientName}`,
            to: `/appointments/${appointmentId}/`,
          },
          { label: "Alter Price" },
        ]}
        title="Alter Appointment Price"
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <Typography gutterBottom>
                This appointment is currently priced at{" "}
                <Value currency={appointment?.price} />. If the appointment has
                been paid for and the price is reduced a refund will be
                processed.
              </Typography>
              <MonetaryField
                fullWidth
                label="New Price"
                name="price"
                required
              />
              <TextField fullWidth label="Reason" name="reason" required />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton
                disabled={appointment === undefined}
                label="Alter Price"
              />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/appointments/${appointmentId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};
export default AlterAppointmentPrice;
