import {
  TextField,
  ToastContext,
  SubmitButton,
  FormLinks,
  Title,
  CircularLoader,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import {
  useDiscontinueProductGroupMutation,
  useProductGroupQuery,
} from "src/queries";

interface IProps {
  productGroupId: string;
}

const FormSchema = z.object({
  discontinuedReason: z.string().min(5),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const DiscontinueProductGroup = ({ productGroupId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: group } = useProductGroupQuery(productGroupId);
  const { mutateAsync: deactivateGroup } =
    useDiscontinueProductGroupMutation(productGroupId);
  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      discontinuedReason: "",
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await deactivateGroup(data);
      addToast("Group discontinued", "success");
      setLocation("/product-groups/");
    } catch {
      addToast("Try again", "error");
    }
  };

  if (group === undefined) {
    return <CircularLoader />;
  }

  return (
    <>
      <Title
        title="Discontinue Product Group"
        breadcrumbs={[
          { label: "Product Groups", to: "/product-groups/" },
          { label: `${group.name}`, to: `/product-groups/${group.id}/` },
          { label: "Discontinue Product Group" },
        ]}
      />
      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Group name</TableCell>
                  <TableCell>{group.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Product type</TableCell>
                  <TableCell>{group.productType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Product count</TableCell>
                  <TableCell>{group.firestoreFormulaIds.length}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent sx={{ paddingTop: 0 }}>
              <TextField
                fullWidth
                label="Reason"
                name="discontinuedReason"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Discontinue Group" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/product-groups/${productGroupId}/edit/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default DiscontinueProductGroup;
