import {
  SkeletonRow,
  Title,
  sortByKey,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";

import { TravelLetter, TravelLetterState } from "src/models";
import TravelLetterRow from "src/pages/TravelLetters/TravelLetterRow";
import { useTravelLettersQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties =
  | "arrivalDate"
  | "assignedTo"
  | "requiresPrescriberApproval"
  | "letterPdfPresent"
  | "timestamp";

const TravelLetters = () => {
  const { data: letters } = useTravelLettersQuery(TravelLetterState.CREATED);

  const [order, setOrder] = useState<Direction>("asc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("arrivalDate");

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let filteredLetters;
  const pendingLetters = letters?.sort(
    sortByKey((letter) => [letter.itinerary[0].arrival], "asc"),
  );

  if (orderBy === "arrivalDate") {
    filteredLetters = pendingLetters?.sort(
      sortByKey((letter) => [letter.itinerary[0].arrival], order),
    );
  } else if (orderBy !== null) {
    const sortKey = (letter: TravelLetter) => [letter[orderBy]];
    filteredLetters = pendingLetters?.sort(sortByKey(sortKey, order));
  } else filteredLetters = pendingLetters;

  return (
    <>
      <Title title="Pending Travel Letters" />
      <Card>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Letter Id</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "timestamp"}
                    direction={order}
                    onClick={onSortClick("timestamp")}
                  >
                    Requested
                  </TableSortLabel>
                </TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "arrivalDate"}
                    direction={order}
                    onClick={onSortClick("arrivalDate")}
                  >
                    Arrival Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>Return Date</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "assignedTo"}
                    direction={order}
                    onClick={onSortClick("assignedTo")}
                  >
                    Assigned To
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "requiresPrescriberApproval"}
                    direction={order}
                    onClick={onSortClick("requiresPrescriberApproval")}
                  >
                    Requires Prescriber Approval
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "letterPdfPresent"}
                    direction={order}
                    onClick={onSortClick("letterPdfPresent")}
                  >
                    Letter Pdf Ready
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLetters === undefined ? (
                <SkeletonRow cols={10} />
              ) : filteredLetters?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10}>No pending letters found.</TableCell>
                </TableRow>
              ) : (
                filteredLetters?.map((letter: TravelLetter) => (
                  <TravelLetterRow
                    key={`${letter.travelLetterId}`}
                    letter={letter}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default TravelLetters;
