import * as z from "zod";

export enum ReasonForApplication {
  ANXIETY = "ANXIETY",
  AGORAPHOBIA = "AGORAPHOBIA",
  ARTHRITIS = "ARTHRITIS",
  ATTENTION_DEFICIT_HYPERACTIVITY_DISORDER_ADHD = "ATTENTION_DEFICIT_HYPERACTIVITY_DISORDER_ADHD",
  AUTISTIC_SPECTRUM_DISORDER = "AUTISTIC_SPECTRUM_DISORDER",
  BREAST_PAIN = "BREAST_PAIN",
  CANCER_RELATED_APPETITE_LOSS = "CANCER_RELATED_APPETITE_LOSS",
  CANCER_RELATED_PAIN_AND_OTHER_SECONDARY_SYMPTOMS = "CANCER_RELATED_PAIN_AND_OTHER_SECONDARY_SYMPTOMS",
  CHEMOTHERAPY_INDUCED_NAUSEA_AND_VOMITING = "CHEMOTHERAPY_INDUCED_NAUSEA_AND_VOMITING",
  CHRONIC_FATIGUE_SYNDROME_ME = "CHRONIC_FATIGUE_SYNDROME_ME",
  CHRONIC_PAIN = "CHRONIC_PAIN",
  COMPLEX_REGIONAL_PAIN_SYNDROME = "COMPLEX_REGIONAL_PAIN_SYNDROME",
  CLUSTER_HEADACHE = "CLUSTER_HEADACHE",
  CROHNS_DISEASE = "CROHNS_DISEASE",
  DEPRESSION = "DEPRESSION",
  EHLERS_DANLOS_SYNDROME = "EHLERS_DANLOS_SYNDROME",
  ENDOMETRIOSIS = "ENDOMETRIOSIS",
  EPILEPSY_ADULT_CHILD = "EPILEPSY_ADULT_CHILD",
  FIBROMYALGIA = "FIBROMYALGIA",
  INSOMNIA = "INSOMNIA",
  LOW_BACK_PAIN_AND_SCIATICA = "LOW_BACK_PAIN_AND_SCIATICA",
  MIGRAINE = "MIGRAINE",
  MULTIPLE_SCLEROSIS_MS = "MULTIPLE_SCLEROSIS_MS",
  MUSCULOSKELETAL_PAIN = "MUSCULOSKELETAL_PAIN",
  NEUROPATHIC_PAIN = "NEUROPATHIC_PAIN",
  OBSESSIVE_COMPULSIVE_DISORDER_OCD = "OBSESSIVE_COMPULSIVE_DISORDER_OCD",
  OTHER_GASTROINTESTINAL_CONDITION = "OTHER_GASTROINTESTINAL_CONDITION",
  PALLIATIVE_CARE = "PALLIATIVE_CARE",
  PARKINSONS_DISEASE = "PARKINSONS_DISEASE",
  SOCIAL_PHOBIA = "SOCIAL_PHOBIA",
  TOURETTES_SYNDROME = "TOURETTES_SYNDROME",
  TRIGEMINAL_NEURALGIA = "TRIGEMINAL_NEURALGIA",
  ULCERATIVE_COLITIS = "ULCERATIVE_COLITIS",
}

export enum PatientStatus {
  REGISTERED = "REGISTERED",
  APPLICATION_IN_ONBOARDING_REVIEW = "APPLICATION_IN_ONBOARDING_REVIEW",
  APPLICATION_CLOSED = "APPLICATION_CLOSED",
}

const applicationSummarySchema = z.object({
  patientId: z.string().uuid(),
  status: z.nativeEnum(PatientStatus),
  createdAt: z.coerce.date(),
  legalName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    ),
  dob: z.coerce.date(),
  nhsNumber: z.string().nullable(),
  email: z.string(),
  phoneNumber: z.string(),
  addressLines: z.array(z.string()),
  postcode: z.string(),
  carerLegalName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    )
    .nullable(),
  carerEmail: z.string().nullable(),
  carerPhoneNumber: z.string().nullable(),
  occupation: z.string(),
  selfReportedCurrentMedication: z.string(),
  selfReportedTreatmentHistory: z.string(),
  selfReportedConditions: z.array(z.nativeEnum(ReasonForApplication)),
  gpName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    )
    .nullable(),
  gpEmail: z.string().nullable(),
  gpPhoneNumber: z.string().nullable(),
  gpAddressLines: z.array(z.string()),
  gpPostcode: z.string().nullable(),
});

export type ApplicationSummary = z.infer<typeof applicationSummarySchema>;

export const newApplicationSummary = (data: unknown): ApplicationSummary =>
  applicationSummarySchema.parse(data);
