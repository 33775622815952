import {
  SubmitButton,
  TextField,
  ToastContext,
  FormLinks,
  Page,
  cz,
  SelectField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { PartnerUserRole } from "src/models";
import {
  usePartnerUserQuery,
  useUpdatePartnerUserRolesMutation,
} from "src/queries";

interface IProps {
  partnerUserId: number;
}

const FormSchema = z.object({
  roles: cz.fieldArray(z.array(z.nativeEnum(PartnerUserRole))),
  reason: cz.field(z.string().min(5)),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const EditPartnerUserRoles = ({ partnerUserId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: partnerUser } = usePartnerUserQuery(partnerUserId);
  const { mutateAsync: updateRoles } =
    useUpdatePartnerUserRolesMutation(partnerUserId);

  const initialValues = {
    roles: partnerUser?.roles ?? [],
    reason: "",
  };

  const methods = useForm<FormType, any, ValidatedType>({
    resolver: zodResolver(FormSchema),
    defaultValues: initialValues,
  });

  useEffect(() => methods.reset(initialValues), [methods, partnerUser]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await updateRoles(data);
      setLocation("/partner-users/");
      addToast("Partner user updated", "success");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        addToast("User already exists", "error");
      } else {
        addToast("Try Again", "error");
      }
    }
  };

  return (
    <Page
      title="Edit Staff Member Roles"
      breadcrumbs={[
        { to: "/partner-users/", label: "Partner Users" },
        {
          label: `${partnerUser?.email ?? ""}`,
          to: `/partner-users/${partnerUser?.id ?? ""}/`,
        },
        { label: "Edit Staff Member" },
      ]}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <SelectField
                fullWidth
                label="Roles"
                name="roles"
                required
                multiple
                options={Object.values(PartnerUserRole).map((role) => ({
                  value: role,
                }))}
              />
              <TextField
                fullWidth
                label="Reason"
                name="reason"
                autoComplete="off"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Edit" />
              <FormLinks
                links={[
                  { label: "Back", to: `/partner-users/${partnerUserId}/` },
                ]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </Page>
  );
};

export default EditPartnerUserRoles;
