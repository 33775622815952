import {
  SubmitButton,
  TextField,
  FormLinks,
  sortByKey,
  AutocompleteField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import { Fragment } from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import * as z from "zod";

import ProductItem from "src/components/ProductItem";
import { FirestoreFormula } from "src/models";
import { useOutOfStockListingsQuery, useProductsQuery } from "src/queries";

const FormSchema = z
  .object({
    firestoreFormulaId: z.string(),
    alternativeFirestoreFormulaIds: z
      .array(z.object({ id: z.string() }))
      .min(1),
    patientInformation: z.string().nullable(),
    internalInformation: z.string().nullable(),
  })
  .superRefine(({ alternativeFirestoreFormulaIds }, context) => {
    const firestoreFormulaIdArr: string[] = [];
    let dupeIndex = null;
    alternativeFirestoreFormulaIds.forEach((id, index) => {
      if (!firestoreFormulaIdArr.includes(id.id)) {
        firestoreFormulaIdArr.push(id.id);
      } else {
        dupeIndex = index;
      }
    });
    return dupeIndex !== null
      ? context.addIssue({
          path: [`${context.path}[${dupeIndex}].id`],
          message: "Cannot add product more than once",
          code: z.ZodIssueCode.custom,
        })
      : true;
  })
  .superRefine(
    ({ alternativeFirestoreFormulaIds, firestoreFormulaId }, context) => {
      const matchedIndex = alternativeFirestoreFormulaIds.findIndex(
        (formula) => formula.id === firestoreFormulaId,
      );
      return matchedIndex === -1
        ? true
        : context.addIssue({
            path: [`${context.path}[${matchedIndex}].id`],
            message: "Product cannot be the same as the out of stock product",
            code: z.ZodIssueCode.custom,
          });
    },
  );

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  initialValues: FormType;
  label: string;
  back: string;
  editing?: boolean;
  onSubmit: (data: ValidatedType) => Promise<any>;
}

const OutOfStockListingForm = ({
  initialValues,
  back,
  label,
  editing = false,
  onSubmit,
}: IProps) => {
  const { data: products } = useProductsQuery(false);
  const { data: listings } = useOutOfStockListingsQuery(true);

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });
  const { watch } = methods;
  const values = watch();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "alternativeFirestoreFormulaIds",
  });

  const transFormBeforeSubmit = async (data: ValidatedType) => {
    await onSubmit({
      ...data,
      alternativeFirestoreFormulaIds: data.alternativeFirestoreFormulaIds.map(
        (product) => ({
          id: product.id,
        }),
      ),
    });
  };

  if (products === undefined || listings === undefined) {
    return <Skeleton height="80px" />;
  }
  const outOfStockfirestoreFormulaIds = listings.reduce((acc, listing) => {
    if (listing.firestoreFormulaId !== initialValues.firestoreFormulaId) {
      acc.push(listing.firestoreFormulaId);
    }
    return acc;
  }, [] as string[]);

  const sortKey = (product: FirestoreFormula) => {
    return [product.type, product.name];
  };

  const filteredProducts = products
    .filter((product) => !outOfStockfirestoreFormulaIds.includes(product.id))
    .sort(sortByKey(sortKey, "asc"));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(transFormBeforeSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid size={{ xs: 12 }}>
                <AutocompleteField
                  fullWidth
                  label="Out of stock product"
                  name="firestoreFormulaId"
                  options={filteredProducts.map((product) => ({
                    value: product.id,
                    label: product.name,
                  }))}
                  groupBy={(option) => {
                    return (
                      products?.find((product) => product.id === option.value)
                        ?.type ?? ""
                    );
                  }}
                  disabled={editing}
                />
              </Grid>
              <TextField
                fullWidth
                label="Patient information"
                name="patientInformation"
                autoComplete="off"
              />
              <TextField
                fullWidth
                label="Internal information"
                name="internalInformation"
                autoComplete="off"
              />

              <>
                {fields.map(({ id }, index) => (
                  <Fragment key={id}>
                    <Grid size={{ xs: 12 }}>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid size={{ xs: 10 }}>
                      <ProductItem
                        name={`alternativeFirestoreFormulaIds[${index}]`}
                        index={index}
                      />
                    </Grid>
                    <Grid textAlign="center" size={{ xs: 2 }}>
                      <IconButton
                        disabled={
                          values.alternativeFirestoreFormulaIds.length <= 1
                        }
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Fragment>
                ))}

                <Grid textAlign="right" size={{ xs: 12 }}>
                  <Button
                    onClick={() => append({ id: "" })}
                    type="button"
                    variant="outlined"
                    disabled={values.firestoreFormulaId === ""}
                  >
                    Add product
                  </Button>
                </Grid>
              </>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton
              label={label}
              disabled={values.alternativeFirestoreFormulaIds.length === 0}
            />
            <FormLinks links={[{ label: "Back", to: back }]} />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default OutOfStockListingForm;
