import * as z from "zod";

import { CommunicationPreferences, Sex } from "src/models";

const patientInfoHistorySchema = z.object({
  id: z.number().int().positive(),
  patientInfoId: z.string().uuid(),
  patientId: z.string().uuid().nullable(),
  nhsNumber: z.string().nullable(),
  communicationPreferences: z.array(z.nativeEnum(CommunicationPreferences)),
  legalName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    ),
  preferredName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    )
    .nullable(),
  pronouns: z.string().nullable(),
  dob: z.coerce.date(),
  email: z.string(),
  phoneNumber: z.string(),
  sexAssignedAtBirth: z.nativeEnum(Sex),
  addressLines: z.array(z.string()),
  postcode: z.string(),
  carerLegalName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    )
    .nullable(),
  carerEmail: z.string().nullable(),
  carerPhoneNumber: z.string().nullable(),
  reason: z.string().nullable(),
  staffId: z.number().int().positive().nullable(),
  staffEmail: z.string().email().nullable(),
  timestamp: z.coerce.date(),
});

export type PatientInfoHistory = z.infer<typeof patientInfoHistorySchema>;

export const newPatientInfoHistory = (data: unknown): PatientInfoHistory =>
  patientInfoHistorySchema.parse(data);
