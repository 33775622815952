import * as z from "zod";

export enum AgreementType {
  GP_SOC_CONSENT = "GP_SOC_CONSENT",
  MARKETING_CONSENT = "MARKETING_CONSENT",
  NCRS_CONSENT = "NCRS_CONSENT",
}

const patientAgreementSchema = z.object({
  id: z.string().uuid(),
  patientId: z.string().uuid(),
  agreementType: z.nativeEnum(AgreementType),
  version: z.number().int().positive(),
  timestamp: z.coerce.date(),
});

export type PatientAgreement = z.infer<typeof patientAgreementSchema>;

export const newPatientAgreement = (data: unknown): PatientAgreement =>
  patientAgreementSchema.parse(data);
