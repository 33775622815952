import {
  DateField,
  Page,
  SubmitButton,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import PaginatedSearch from "src/components/PaginatedSearch";
import { ApplicationSummary } from "src/models/onboardingApplicants";
import OnboardingTable from "src/pages/Onboarding/OnboardingTable";
import { usePaginationSettings } from "src/pagination";

const FormSchema = z.object({
  dateOfBirth: z.coerce.date().nullable(),
});

type FormType = z.input<typeof FormSchema>;

const Onboarding = () => {
  const [
    paginationSettings,
    { setPageNumber, setPageSize, setSearchQuery, toggleSort },
  ] = usePaginationSettings<ApplicationSummary>({
    pageNumber: 0,
    pageSize: 100,
    sortColumn: "createdAt",
    sortDirection: "desc",
  });

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: { dateOfBirth: null },
  });

  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);

  const onSubmit = (data: FormType) => {
    setDateOfBirth(data.dateOfBirth);
  };
  const reset = () => {
    methods.reset();
    setDateOfBirth(null);
  };

  return (
    <Page title="Onboarding">
      <Card>
        <CardContent>
          <PaginatedSearch
            setSearchQuery={setSearchQuery}
            paginationSettings={paginationSettings}
            label="Search applications"
            placeholder="Legal name, preferred name, email, postcode"
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2} alignItems="center">
                <Grid size={{ xs: 6, sm: 4 }}>
                  <DateField
                    fullWidth
                    name="dateOfBirth"
                    label="Search by Date of Birth"
                  />
                </Grid>
                <Grid size={{ xs: 6, sm: 2 }}>
                  <SubmitButton label="Filter" fullWidth />
                </Grid>
                <Grid size={{ xs: 6, sm: 1 }}>
                  <Button onClick={() => reset()}>Reset</Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
        <OnboardingTable
          paginationSettings={paginationSettings}
          dateOfBirth={dateOfBirth}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          toggleSort={toggleSort}
        />
      </Card>
    </Page>
  );
};

export default Onboarding;
