import {
  Search,
  Page,
  useDebounce,
  useHash,
} from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Tab from "@mui/material/Tab";
import { ChangeEvent, useState } from "react";
import { Link } from "wouter";

import type { States } from "src/pages/PartnerUsers/PartnerUserTable";
import PartnerUserTable from "src/pages/PartnerUsers/PartnerUserTable";

const PartnerUsers = () => {
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);
  const [tab, setTab] = useHash<States>("enabled");

  return (
    <TabContext value={tab}>
      <Page
        title="Partner Users"
        disablePadding={true}
        actions={
          <Button component={Link} to="/partner-users/new/" variant="contained">
            Add Partner User
          </Button>
        }
        tabs={
          <TabList
            onChange={(_, value: string) => setTab(value as States)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Enabled" value="enabled" />
            <Tab label="Disabled" value="disabled" />
          </TabList>
        }
      >
        <CardContent>
          <Search
            fullWidth
            label="Search"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setRawSearch(event.target.value)
            }
            value={rawSearch}
          />
        </CardContent>
        <PartnerUserTable search={search} state={tab} />
      </Page>
    </TabContext>
  );
};

export default PartnerUsers;
