import { Value } from "@curaleaf-international/components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { PatientInfoHistory } from "src/models";

interface IProps {
  onClose: () => void;
  open: boolean;
  historyEntry: PatientInfoHistory | null;
}

const PatientInfoHistoryEntryDialog = ({
  onClose,
  open,
  historyEntry,
}: IProps) => {
  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle>GP Details History Entry</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>
                  <Value dateTime={historyEntry?.timestamp} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Changed By</TableCell>
                <TableCell>
                  {historyEntry?.patientId ? (
                    "Patient"
                  ) : historyEntry?.staffId ? (
                    <Link
                      component={WLink}
                      href={`/staff/${historyEntry.staffId}/`}
                    >
                      {historyEntry.staffEmail}
                    </Link>
                  ) : (
                    "System"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reason</TableCell>
                <TableCell>
                  <Value text={historyEntry?.reason} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Legal Name</TableCell>
                <TableCell>
                  <Value text={historyEntry?.legalName} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Preferred Name</TableCell>
                <TableCell>
                  <Value text={historyEntry?.preferredName} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pronouns</TableCell>
                <TableCell>
                  <Value text={historyEntry?.pronouns} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>DOB</TableCell>
                <TableCell>
                  <Value date={historyEntry?.dob} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>NHS Number</TableCell>
                <TableCell>
                  <Value text={historyEntry?.nhsNumber} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>
                  <Value text={historyEntry?.email} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone Number</TableCell>
                <TableCell>
                  <Value text={historyEntry?.phoneNumber} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>
                  <Value text={historyEntry?.addressLines.join(", ")} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Postcode</TableCell>
                <TableCell>
                  <Value text={historyEntry?.postcode} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carer Legal Name</TableCell>
                <TableCell>
                  <Value text={historyEntry?.carerLegalName} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carer Email</TableCell>
                <TableCell>
                  <Value text={historyEntry?.carerEmail} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carer Phone Number</TableCell>
                <TableCell>
                  <Value text={historyEntry?.carerPhoneNumber} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Communication Preferences</TableCell>
                <TableCell>
                  <Value
                    text={historyEntry?.communicationPreferences.join(", ")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default PatientInfoHistoryEntryDialog;
