import { zodDecimal } from "@curaleaf-international/components";
import * as z from "zod";

export enum ProductAvailability {
  PRESCRIBABLE = "PRESCRIBABLE",
  UNPRESCRIBABLE = "UNPRESCRIBABLE",
}

const productSchema = z.object({
  id: z.string().uuid(),
  formulaId: z.string().uuid(),
  availability: z.nativeEnum(ProductAvailability),
  curaleafPharmacyId: z.string().nullable(),
  quantity: zodDecimal(),
  price: zodDecimal(),
});

export type Product = z.infer<typeof productSchema>;

export const newProduct = (data: unknown) => productSchema.parse(data);
