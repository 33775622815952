import * as z from "zod";

const partnerUserSessionSchema = z.object({
  id: z.number(),
  remoteAddr: z.string(),
  partnerUserId: z.number(),
  start: z.coerce.date(),
  stop: z.coerce.date(),
  userAgent: z.string(),
  valid: z.boolean(),
});

export type PartnerUserSession = z.infer<typeof partnerUserSessionSchema>;

export const newPartnerUserSession = (data: unknown): PartnerUserSession =>
  partnerUserSessionSchema.parse(data);
