import axios from "axios";

import { PatientGpDetails, newPatientGpDetails } from "src/models";
import { keys } from "src/queries/keys";
import { useQuery } from "src/query";

export const usePatientGpDetailsQuery = (patientId: string) =>
  useQuery<PatientGpDetails>({
    queryKey: keys.patientGpDetails(patientId),
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/patient-gp-details/patient/${patientId}/`,
        { signal },
      );
      return newPatientGpDetails(response.data);
    },
  });
