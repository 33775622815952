import { Value } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import ClinicalReviewActions from "src/components/ClinicalReviewActions";
import { ClinicalReview } from "src/models";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  clinicalReview: ClinicalReview;
}

const ClinicalReviewTableRow = ({ clinicalReview }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={WLink}
          to={`/clinical-reviews/${clinicalReview.id}/`}
        >
          View
        </MenuItem>
        <ClinicalReviewActions clinicalReview={clinicalReview} />
      </Menu>
      <TableRow>
        <TableCell>
          <Link
            component={WLink}
            to={`/clinical-reviews/${clinicalReview.id}/`}
          >
            {clinicalReview.id}
          </Link>
        </TableCell>
        <TableCell>
          {convertEnumValueToReadableString(clinicalReview.type, " ")}
        </TableCell>
        <TableCell>
          <Value
            link={{
              label: clinicalReview.patientName,
              to: `/patients/${clinicalReview.patientId}/`,
            }}
          />
        </TableCell>
        <TableCell>
          {clinicalReview.assignedConsultantId ? (
            <Link
              component={WLink}
              to={`/clinicians/${clinicalReview.assignedConsultantId}/`}
            >
              {clinicalReview.assignedConsultantName}
            </Link>
          ) : (
            "Not Assigned"
          )}
        </TableCell>
        <TableCell>
          <Link component={WLink} to={`/staff/${clinicalReview.stateStaffId}/`}>
            {clinicalReview.stateStaffEmail}
          </Link>
        </TableCell>
        <TableCell align="center">
          <Value dateTime={clinicalReview.stateTimestamp} />
        </TableCell>
        <TableCell align="center">
          <Value text={clinicalReview.stateReason} />
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ClinicalReviewTableRow;
