import {
  SelectField,
  SubmitButton,
  TextField,
  FormLinks,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import { Fragment } from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import * as z from "zod";

import ProductItem from "src/components/ProductItem";
import type { IProductGroupData } from "src/queries";
import { useProductsQuery } from "src/queries";

const FormSchema = z
  .object({
    name: z.string(),
    firestoreFormulaIds: z.array(z.object({ id: z.string() })).min(1),
    productType: z.string(),
  })
  .superRefine(({ firestoreFormulaIds }, context) => {
    const firestoreFormulaIdArr: string[] = [];
    let dupeIndex = null;
    firestoreFormulaIds.forEach((id, index) => {
      if (!firestoreFormulaIdArr.includes(id.id)) {
        firestoreFormulaIdArr.push(id.id);
      } else {
        dupeIndex = index;
      }
    });
    return dupeIndex !== null
      ? context.addIssue({
          path: [`${context.path}[${dupeIndex}].id`],
          message: "Cannot add product more than once",
          code: z.ZodIssueCode.custom,
        })
      : true;
  });

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  initialValues: FormType;
  label: string;
  back: string;
  onSubmit: (data: IProductGroupData) => Promise<any>;
}

const ProductGroupForm = ({ initialValues, back, label, onSubmit }: IProps) => {
  const { data: products } = useProductsQuery(false);

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });
  const { watch } = methods;
  const values = watch();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "firestoreFormulaIds",
  });

  const transFormBeforeSubmit = async (data: ValidatedType) => {
    await onSubmit({
      ...data,
      firestoreFormulaIds: data.firestoreFormulaIds.map(
        (product) => product.id,
      ),
    });
  };

  if (products === undefined) {
    return <Skeleton height="80px" />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(transFormBeforeSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                autoComplete="off"
              />
              <SelectField
                fullWidth
                label="Product type"
                name="productType"
                options={products
                  .reduce((acc: string[], product) => {
                    if (
                      product.type &&
                      !acc.includes(product.type) &&
                      !product.formularies.includes("Other Medicines")
                    ) {
                      acc.push(product.type);
                    }
                    return acc;
                  }, [])
                  .sort()
                  .map((type) => ({
                    label: type,
                    value: type,
                  }))}
              />

              <>
                {fields.map(({ id }, index) => (
                  <Fragment key={id}>
                    <Grid size={{ xs: 12 }}>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid size={{ xs: 10 }}>
                      <ProductItem
                        type={values.productType}
                        name={`firestoreFormulaIds[${index}]`}
                        index={index}
                      />
                    </Grid>
                    <Grid textAlign="center" size={{ xs: 2 }}>
                      <IconButton
                        disabled={values.firestoreFormulaIds.length <= 1}
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Fragment>
                ))}

                <Grid textAlign="right" size={{ xs: 12 }}>
                  <Button
                    onClick={() => append({ id: "" })}
                    type="button"
                    variant="outlined"
                    disabled={values.productType === ""}
                  >
                    Add product
                  </Button>
                </Grid>
              </>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton
              label={label}
              disabled={values.firestoreFormulaIds.length === 0}
            />
            <FormLinks links={[{ label: "Back", to: back }]} />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default ProductGroupForm;
