import axios from "axios";

import { PatientInfo, newPatientInfo } from "src/models";
import { keys } from "src/queries/keys";
import { useQuery } from "src/query";

export const usePatientInfoQuery = (patientId: string | undefined) =>
  useQuery<PatientInfo>({
    queryKey: keys.patientInfo(patientId),
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/patient-info/patient/${patientId}/`,
        { signal },
      );
      return newPatientInfo(response.data);
    },
    enabled: patientId !== undefined,
  });
