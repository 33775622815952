import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { usePatientApplicationDetailsQuery } from "src/queries/patientApplicationDetails";

interface IProps {
  patientId: string;
}

const PatientApplicationDetails = ({ patientId }: IProps) => {
  const { data: details } = usePatientApplicationDetailsQuery(patientId);

  return (
    <Card>
      <CardHeader title="Other Application Details" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>
                <Value text={patientId} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Affiliate Code</TableCell>
              <TableCell>
                <Value text={details?.affiliateCode ?? "N/A"} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>How They Heard About Us</TableCell>
              <TableCell>
                <Value text={details?.howHearAboutUs} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Transfer From</TableCell>
              <TableCell>
                <Value text={details?.transferFrom ?? "N/A"} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default PatientApplicationDetails;
