import MenuItem from "@mui/material/MenuItem";
import { Link } from "wouter";

import { ClinicalReview, ClinicalReviewState } from "src/models";

interface IProps {
  clinicalReview?: ClinicalReview;
}

const ClinicalReviewActions = ({ clinicalReview }: IProps) => (
  <>
    <MenuItem
      component={Link}
      disabled={
        clinicalReview === undefined ||
        clinicalReview.state !== ClinicalReviewState.PENDING
      }
      to={`/clinical-reviews/${clinicalReview?.id}/cancel/`}
    >
      Cancel
    </MenuItem>
    <MenuItem
      component={Link}
      disabled={
        clinicalReview === undefined ||
        clinicalReview.state !== ClinicalReviewState.DEFERRED
      }
      to={`/clinical-reviews/${clinicalReview?.id}/close/`}
    >
      Close
    </MenuItem>
  </>
);

export default ClinicalReviewActions;
