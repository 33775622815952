import {
  ToastContext,
  Title,
  CircularLoader,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { Link, useLocation } from "wouter";

import OutOfStockListingForm, {
  ValidatedType,
} from "src/components/OutOfStockListingForm";
import {
  useEditOutOfStockListingMutation,
  useOutOfStockListingQuery,
} from "src/queries";

interface IProps {
  listingId: string;
}

const EditOutOfStockListing = ({ listingId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: listing } = useOutOfStockListingQuery(listingId);
  const { mutateAsync: editListing } =
    useEditOutOfStockListingMutation(listingId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await editListing({
        ...data,
        alternativeFirestoreFormulaIds: data.alternativeFirestoreFormulaIds.map(
          (formula) => formula.id,
        ),
      });
      addToast("Out of stock listing updated", "success");
      setLocation(`/out-of-stock-listings/${listingId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return listing === undefined ? (
    <CircularLoader />
  ) : (
    <>
      <Title
        title="Edit Out of Stock Listing"
        actions={
          <Button
            component={Link}
            to={`/out-of-stock-listings/${listingId}/close/`}
            variant="contained"
            color="warning"
          >
            Mark in stock
          </Button>
        }
        breadcrumbs={[
          { label: "Out of Stock Listings", to: "/out-of-stock-listings/" },
          {
            label: `${listing.productName}`,
            to: `/out-of-stock-listings/${listing.id}/`,
          },
          { label: `Edit Out of Stock Listing` },
        ]}
      />
      <OutOfStockListingForm
        initialValues={{
          firestoreFormulaId: listing.firestoreFormulaId,
          alternativeFirestoreFormulaIds:
            listing.alternativeFirestoreFormulaIds.map((id) => ({
              id,
            })),
          patientInformation: listing.patientInformation ?? "",
          internalInformation: listing.internalInformation ?? "",
        }}
        editing={true}
        label="Edit"
        back={`/out-of-stock-listings/${listingId}/`}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default EditOutOfStockListing;
