import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { ApplicationSummary } from "src/models/onboardingApplicants";

interface IProps {
  patient: ApplicationSummary | undefined;
}

const ContactDetails = ({ patient }: IProps) => {
  return (
    <Card>
      <CardHeader title="Contact Details" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                <Value text={patient?.email} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone Number</TableCell>
              <TableCell>
                <Value text={patient?.phoneNumber} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>
                <Value
                  text={
                    patient?.addressLines && patient.addressLines.join(", ")
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Postcode</TableCell>
              <TableCell>
                <Value text={patient?.postcode} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Carer Legal Name</TableCell>
              <TableCell>
                <Value text={patient?.carerLegalName} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Carer Email</TableCell>
              <TableCell>
                <Value text={patient?.carerEmail} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Carer Phone Number</TableCell>
              <TableCell>
                <Value text={patient?.carerPhoneNumber} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ContactDetails;
