import { Value } from "@curaleaf-international/components";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { usePartnerUserQuery } from "src/queries";

interface IProps {
  partnerUserId: number;
}

const PartnerUserDetails = ({ partnerUserId }: IProps) => {
  const { data: partnerUser } = usePartnerUserQuery(partnerUserId);

  return (
    <>
      <CardHeader title="Info" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>
              <Value text={partnerUser?.email} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Created</TableCell>
            <TableCell>
              <Value dateTime={partnerUser?.created} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Login</TableCell>
            <TableCell>
              <Value dateTime={partnerUser?.lastLogin} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Active Sessions</TableCell>
            <TableCell>
              <Value text={partnerUser?.activeSessions.toString()} />{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Disabled</TableCell>
            <TableCell>
              <Value dateTime={partnerUser?.disabled} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default PartnerUserDetails;
