import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { ApplicationSummary } from "src/models/onboardingApplicants";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  patient: ApplicationSummary | undefined;
}

const MedicalDetails = ({ patient }: IProps) => {
  return (
    <Card>
      <CardHeader title="Medical Details" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Occupation</TableCell>
              <TableCell>
                <Value text={patient?.occupation} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Self-Reported Current Medication</TableCell>
              <TableCell>
                <Value text={patient?.selfReportedCurrentMedication} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Self-Reported Treatment History</TableCell>
              <TableCell>
                <Value text={patient?.selfReportedTreatmentHistory} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Reason(s) for Applying</TableCell>
              <TableCell>
                <Value
                  text={patient?.selfReportedConditions
                    .map((condition) =>
                      convertEnumValueToReadableString(condition, " "),
                    )
                    .join(", ")}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default MedicalDetails;
