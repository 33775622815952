import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Fuse from "fuse.js";
import { memo, useState } from "react";

import { PartnerUser } from "src/models";
import PartnerUserRow from "src/pages/PartnerUsers/PartnerUserRow";
import { usePartnerUsersQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties =
  | "activeSessions"
  | "created"
  | "email"
  | "lastLogin"
  | "id";
export type States = "disabled" | "enabled";

interface IProps {
  search: string;
  state?: States;
}

const PartnerUserTable = ({ search, state }: IProps) => {
  const { data: partnerUsers } = usePartnerUsersQuery();
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("created");
  const sortKey = (partnerUser: PartnerUser) => [partnerUser[orderBy]];

  let rows;
  if (partnerUsers === undefined) {
    rows = <SkeletonRow cols={7} />;
  } else {
    let filteredPartnerUsers = Object.values(partnerUsers).filter(
      (user) => (state === "enabled") === (user.disabled === null),
    );

    if (search !== "") {
      const fuse = new Fuse(filteredPartnerUsers, {
        keys: ["email"],
      });
      filteredPartnerUsers = fuse.search(search).map((value) => value.item);
    } else {
      filteredPartnerUsers = filteredPartnerUsers.sort(
        sortByKey(sortKey, order),
      );
    }

    rows = filteredPartnerUsers.map((user) => (
      <PartnerUserRow key={user.id} partnerUser={user} />
    ));
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "email"}
                direction={order}
                onClick={onSortClick("email")}
              >
                Email
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "created"}
                direction={order}
                onClick={onSortClick("created")}
              >
                Created
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "activeSessions"}
                direction={order}
                onClick={onSortClick("activeSessions")}
              >
                Active sessions
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "lastLogin"}
                direction={order}
                onClick={onSortClick("lastLogin")}
              >
                Last login
              </TableSortLabel>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(PartnerUserTable);
