import { useQuery, useMutation } from "@curaleaf-international/components";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Decimal } from "decimal.js";

import {
  Formula,
  newFormula,
  Form,
  SuggestedUse,
  FormulaState,
} from "src/models";

export const useFormulasQuery = () =>
  useQuery<Formula[]>({
    queryKey: ["formulas"],
    queryFn: async () => {
      const response = await axios.get("/v1/formulas/");
      return response.data.formulas.map((data: unknown) => newFormula(data));
    },
  });

export const useFormulaQuery = (formulaId: string | undefined) => {
  return useQuery<Formula>({
    queryKey: ["formulas", formulaId],
    queryFn: async () => {
      const response = await axios.get(`/v1/formulas/${formulaId}/`);
      return newFormula(response.data);
    },
    enabled: formulaId !== undefined,
  });
};

interface ICreateFormulaData {
  brand: string;
  controlled: boolean;
  form: Form;
  internalName: string;
  manufacturer: string | null;
  registeredName: string;
  startingState: string;
}

export const useCreateFormulaMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ICreateFormulaData) =>
      await axios.post(`/v1/formulas/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};

interface IEditFormulaData {
  suggestedUse: SuggestedUse[];
  quantityPerCartridge: Decimal | null;
}

export const useUpdateCannabisFormulaMutation = (formulaId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IEditFormulaData) =>
      await axios.put(`/v1/formulas/${formulaId}/edit-cannabis/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["formulas", formulaId],
      });
    },
  });
};

interface IFormulStateEditData {
  state: FormulaState;
  reason: string;
}

export const useUpdateFormulaStateMutation = (formulaId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IFormulStateEditData) => {
      await axios.put(`/v1/formulas/${formulaId}/state/`, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["formulas"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["formulaStateHistory", formulaId],
      });
    },
  });
};
