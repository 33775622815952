import {
  EmailField,
  SubmitButton,
  FormLinks,
  TextField,
  ToastContext,
  Page,
  cz,
  SelectField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { PartnerUserRole } from "src/models";
import { useCreatePartnerUserMutation } from "src/queries";

const FormSchema = z.object({
  email: cz.field(z.string().email()),
  reason: cz.field(z.string()),
  roles: cz.fieldArray(z.array(z.nativeEnum(PartnerUserRole))),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const CreatePartnerUser = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createPartnerUser } = useCreatePartnerUserMutation();

  const initialValues = {
    email: "",
    reason: "",
    roles: [],
  };

  const methods = useForm<FormType, any, ValidatedType>({
    resolver: zodResolver(FormSchema),
    defaultValues: initialValues,
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await createPartnerUser(data);
      setLocation("/partner-users/");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        addToast("Email already exists", "error");
      } else {
        addToast("Try Again", "error");
      }
    }
  };

  return (
    <Page
      title="Create a partner user"
      breadcrumbs={[{ label: "Partner Users", to: "/partner-users/" }]}
      disablePadding={true}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <EmailField
                fullWidth
                label="Email"
                name="email"
                autoComplete="off"
                required
              />
              <SelectField
                fullWidth
                label="Roles"
                multiple
                name="roles"
                options={Object.keys(PartnerUserRole).map((role) => ({
                  value: role,
                }))}
                required
              />
              <TextField
                fullWidth
                helperText="Please explain why the roles are appropriate"
                label="Reason for Role"
                name="reason"
                required
                autoComplete="off"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Create" />
              <FormLinks links={[{ label: "Back", to: "/partner-users/" }]} />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </Page>
  );
};

export default CreatePartnerUser;
