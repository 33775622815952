import { Value } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import RequiresRoles from "src/components/RequiresRoles";
import { Product, Role } from "src/models";

interface IProps {
  product: Product;
}

const ProductTableRow = ({ product }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <RequiresRoles roles={[Role.PHARMACIST, Role.PHARMACIST_TECHNICIAN]}>
          <MenuItem
            component={WLink}
            onClick={handleClose}
            disabled={product.curaleafPharmacyId !== null}
            to={`/products/${product.id}/edit/`}
          >
            Edit
          </MenuItem>
        </RequiresRoles>
      </Menu>
      <TableRow>
        <TableCell>
          <Value text={product.quantity.toString()} />
        </TableCell>
        <TableCell>
          <Value currency={product.price} />
        </TableCell>
        <TableCell>
          <Value text={product.availability} />
        </TableCell>
        <TableCell>
          <Value text={product.curaleafPharmacyId ? "Yes" : "No"} />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProductTableRow;
