import {
  DecimalField,
  FormLinks,
  NumberField,
  SelectField,
  SubmitButton,
  zodDecimal,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { ProductAvailability } from "src/models";
import { useFormulaQuery } from "src/queries";

const FormSchema = z.object({
  availability: z.union([z.nativeEnum(ProductAvailability), z.literal("")]),
  quantity: zodDecimal(),
  price: zodDecimal(),
});

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  formulaId?: string;
  initialValues: FormType;
  label: string;
  disabled: boolean;
  onSubmit: (data: ValidatedType) => Promise<any>;
}

const ProductForm = ({
  disabled,
  formulaId,
  initialValues,
  label,
  onSubmit,
}: IProps) => {
  const { data: formula } = useFormulaQuery(formulaId);

  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(initialValues), [initialValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {`${formula?.internalName} Product`}
          </Typography>
          <SelectField
            size="small"
            options={Object.values(ProductAvailability).map((availability) => ({
              value: availability,
            }))}
            name="availability"
            label="Availability"
            fullWidth
          />
          <DecimalField fullWidth name="price" label="Price" />
          <NumberField fullWidth required label="Quantity" name="quantity" />
        </CardContent>
        <Divider />
        <CardActions>
          <SubmitButton label={label} disabled={disabled} />
          <FormLinks
            links={[
              {
                label: "Back",
                to: `/formulas/${formula?.id}/#products`,
              },
            ]}
          />
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default ProductForm;
