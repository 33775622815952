import { formatDate, formatDateTime } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import RequiresRoles from "src/components/RequiresRoles";
import { Role, PartnerUser } from "src/models";

interface IProps {
  partnerUser: PartnerUser;
}

const PartnerUserRow = ({ partnerUser }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <RequiresRoles roles={[Role.STAFF_MANAGER]}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            component={WLink}
            onClick={handleClose}
            to={`/partner-users/${partnerUser.id}/edit-roles/`}
          >
            Edit Roles
          </MenuItem>
          <MenuItem
            component={WLink}
            onClick={handleClose}
            to={`/partner-users/${partnerUser.id}/disabled/`}
          >
            {partnerUser.disabled === null ? "Disable" : "Enable"}
          </MenuItem>
        </Menu>
      </RequiresRoles>
      <TableRow>
        <TableCell>
          <Link component={WLink} to={`/partner-users/${partnerUser.id}/`}>
            {partnerUser.email}
          </Link>
        </TableCell>
        <TableCell>{formatDate(partnerUser.created)}</TableCell>
        <TableCell>{partnerUser.activeSessions}</TableCell>
        <TableCell>
          {partnerUser.lastLogin !== null
            ? formatDateTime(partnerUser.lastLogin)
            : ""}
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PartnerUserRow;
