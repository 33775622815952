import {
  formatDateTime,
  SkeletonRow,
} from "@curaleaf-international/components";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

export interface HistorySummary {
  id: number;
  patientId: string | null;
  reason: string | null;
  staffId: number | null;
  staffEmail: string | null;
  timestamp: Date;
}

interface IProps {
  setOpen: (open: boolean) => void;
  setEntryId: (entryId: number) => void;
  history: HistorySummary[];
}

const HistorySummaryTable = ({ setOpen, setEntryId, history }: IProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Changed By</TableCell>
            <TableCell>Preview</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history ? (
            history.map((entry: HistorySummary) => (
              <TableRow key={entry.id}>
                <TableCell>{formatDateTime(entry.timestamp)}</TableCell>
                <TableCell>{entry.reason}</TableCell>
                <TableCell>
                  {entry.patientId ? (
                    "Patient"
                  ) : entry.staffId ? (
                    <Link component={WLink} href={`/staff/${entry.staffId}/`}>
                      {entry.staffEmail}
                    </Link>
                  ) : (
                    "Worker"
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setEntryId(entry.id);
                    }}
                  >
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <SkeletonRow cols={4} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistorySummaryTable;
