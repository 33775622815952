import { alpha, styled } from "@mui/material/styles";

const MiniCalendarStyleContainer = styled("div")(({ theme }) => ({
  "& .fc": {
    "--fc-bg-event-opacity": 1,
    "--fc-border-color": theme.palette.divider,
    "--fc-today-bg-color": alpha(theme.palette.divider, 0.3),
    "--fc-event-bg-color": alpha(theme.palette.success.light, 0.1),
  },
  "& .fc-daygrid-day-number": {
    cursor: "pointer",
    fontSize: 12,
  },
  "& .fc-day:hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.grey[100],
  },
  "& .fc-event-title-container": {
    display: "flex",
    alignItems: "center",
  },
  "& .fc-button": {
    backgroundColor: theme.palette.primary.main,
    border: "none",
    boxShadow: "none !important",
    fontSize: 12,
  },
  "& .fc-button:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& .fc-button-primary:not(:disabled).fc-button-active": {
    backgroundColor: theme.palette.action.disabled,
  },
  "& .fc-button:disabled": {
    backgroundColor: theme.palette.action.disabled,
    opacity: 1,
  },
  " .fc-button.fc-button-primary:active": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events": {
    minHeight: 0,
  },
  "& .fc .fc-toolbar.fc-header-toolbar": {
    marginBottom: 4,
  },
  "& .fc-toolbar-title": {
    fontWeight: theme.typography.body1.fontWeight,
    fontSize: theme.typography.body1.fontSize,
  },
  "& .fc .fc-col-header-cell-cushion, .fc-list-day": {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    fontSize: 12,
    textTransform: theme.typography.overline.textTransform,
  },
  " & .fc-daygrid-day-events": {
    justifyContent: "center",
    alignItems: "center",
  },
  "& .fc-scrollgrid": {
    borderColor: "transparent",
  },
  "& .fc-scrollgrid td:last-of-type": {
    borderRightColor: "transparent",
  },
  '& [role="row"]:last-of-type td': {
    borderBottomColor: "transparent",
  },
  '& th[role="presentation"]': {
    borderRightColor: "transparent",
  },
  "& .fc-list": {
    borderColor: "transparent",
  },
  "@media (max-width: 600px)": {
    "& .fc-button": {
      fontSize: 14,
    },
  },
  "@media (prefers-color-scheme: dark)": {
    "& .fc-col-header-cell-cushion, .fc-list-day": {
      backgroundColor: "black",
    },
    "& .fc": {
      "--fc-page-bg-color": "transparent",
    },
    "& .fc-day:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[600],
    },
  },
}));

export default MiniCalendarStyleContainer;
