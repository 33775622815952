import { formatDateTime, sortByKey } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { PartnerUserRoles } from "src/models";
import { usePartnerUserRolesQuery } from "src/queries";

interface IProps {
  partnerUserId: number;
}

const PartnerUserRoleHistory = ({ partnerUserId }: IProps) => {
  const { data: partnerUserRoles } = usePartnerUserRolesQuery(partnerUserId);
  const sortKey = (partnerUserRole: PartnerUserRoles) => [
    partnerUserRole.activeFrom,
  ];
  const currentDateTime = new Date();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Role(s)</TableCell>
            <TableCell>Active From</TableCell>
            <TableCell>Active To</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Granted By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partnerUserRoles?.sort(sortByKey(sortKey, "desc"))?.map((role) => (
            <TableRow key={role.id}>
              <TableCell>
                {role.roles.join(", ")}
                {role.roles.length === 0 ? "NO ASSIGNED ROLES" : ""}
                {role.expires > currentDateTime ? <b> (CURRENT) </b> : ""}
              </TableCell>
              <TableCell>{formatDateTime(role.activeFrom)}</TableCell>
              <TableCell>{formatDateTime(role.expires)}</TableCell>
              <TableCell>{role.reason}</TableCell>
              <TableCell>{role.grantedByEmail}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PartnerUserRoleHistory;
