import { useQuery, useMutation } from "@curaleaf-international/components";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Decimal } from "decimal.js";

import { Product, newProduct, ProductAvailability } from "src/models";

export const useProductsForFormulaQuery = (formulaId: string) => {
  return useQuery<Product[]>({
    queryKey: ["products", formulaId],
    queryFn: async () => {
      const response = await axios.get(`/v1/products/formula/${formulaId}/`);
      return response.data.products.map((data: unknown) => newProduct(data));
    },
  });
};

export const useProductQuery = (productId: string) => {
  return useQuery<Product>({
    queryKey: ["products", productId],
    queryFn: async () => {
      const response = await axios.get(`/v1/products/${productId}/`);
      return newProduct(response.data);
    },
  });
};

interface ICreateProductData {
  formulaId: string;
  availability: ProductAvailability;
  quantity: Decimal;
  price: Decimal;
}

export const useCreateProductMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ICreateProductData) =>
      await axios.post(`/v1/products/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};

interface IProductEditData {
  availability: ProductAvailability;
  quantity: Decimal;
  price: Decimal;
}

export const useUpdateProductMutation = (productId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IProductEditData) => {
      await axios.put(`/v1/products/${productId}/`, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["products"],
      });
    },
  });
};
