import axios from "axios";

import {
  PatientAgreement,
  newPatientAgreement,
} from "src/models/patientAgreements";
import { keys } from "src/queries/keys";
import { useQuery } from "src/query";

export const usePatientAgreementsQuery = (patientId: string) =>
  useQuery<PatientAgreement[]>({
    queryKey: keys.patientAgreements(patientId),
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/agreements/patient/${patientId}/`, {
        signal,
      });
      return response.data.agreements.map((data: unknown) =>
        newPatientAgreement(data),
      );
    },
  });
