export const keys = {
  applicationSummaries: (...args: any[]) => ["applicationSummaries", ...args],
  applicationSummary: (...args: any[]) => ["applicationSummary", ...args],
  patientAgreements: (...args: any[]) => ["patientAgreements", ...args],
  patientApplicationDetails: (...args: any[]) => [
    "patientApplicationDetails",
    ...args,
  ],
  patientGpDetails: (...args: any[]) => ["patientGpDetails", ...args],
  patientGpDetailsHistory: (...args: any[]) => [
    "patientGpDetailsHistory",
    ...args,
  ],
  patientInfo: (...args: any[]) => ["patientInfo", ...args],
  patientInfoHistory: (...args: any[]) => ["patientInfoHistory", ...args],
  patientMedicalDetails: (...args: any[]) => ["patientMedicalDetails", ...args],
  patientMedicalDetailsHistory: (...args: any[]) => [
    "patientMedicalDetailsHistory",
    ...args,
  ],
};

export const relatedKeys: Record<keyof typeof keys, any[]> = {
  applicationSummaries: [keys.applicationSummaries()],
  applicationSummary: [keys.applicationSummaries(), keys.applicationSummary()],
  patientAgreements: [keys.patientAgreements(), keys.applicationSummaries()],
  patientApplicationDetails: [keys.patientApplicationDetails()],
  patientGpDetails: [
    keys.patientGpDetails(),
    keys.applicationSummary(),
    keys.patientGpDetailsHistory(),
  ],
  patientGpDetailsHistory: [keys.patientGpDetailsHistory()],
  patientInfo: [
    keys.patientInfoHistory(),
    keys.patientInfo(),
    keys.applicationSummary(),
    keys.applicationSummaries(),
  ],
  patientInfoHistory: [keys.patientInfoHistory()],
  patientMedicalDetails: [
    keys.patientMedicalDetails(),
    keys.patientMedicalDetailsHistory(),
    keys.applicationSummary(),
    keys.applicationSummaries(),
  ],
  patientMedicalDetailsHistory: [keys.patientMedicalDetailsHistory()],
};
