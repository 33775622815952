import { Value } from "@curaleaf-international/components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { PatientMedicalDetailsHistory } from "src/models";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  onClose: () => void;
  open: boolean;
  historyEntry: PatientMedicalDetailsHistory | null;
}

const MedicalDetailsHistoryEntryDialog = ({
  onClose,
  open,
  historyEntry,
}: IProps) => {
  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle>Medical Details History Entry</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>
                  <Value dateTime={historyEntry?.timestamp} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Changed By</TableCell>
                <TableCell>
                  {historyEntry?.patientId ? (
                    "Patient"
                  ) : historyEntry?.staffId ? (
                    <Link
                      component={WLink}
                      href={`/staff/${historyEntry.staffId}/`}
                    >
                      {historyEntry.staffEmail}
                    </Link>
                  ) : (
                    "System"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reason</TableCell>
                <TableCell>
                  <Value text={historyEntry?.reason} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Alcohol Use</TableCell>
                <TableCell>
                  <Value text={historyEntry?.alcoholUse} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Self-Reported Current Medication</TableCell>
                <TableCell>
                  <Value text={historyEntry?.selfReportedCurrentMedication} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Self-Reported Treatment History</TableCell>
                <TableCell>
                  <Value text={historyEntry?.selfReportedTreatmentHistory} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reason(s) for Applying</TableCell>
                <TableCell>
                  <Value
                    text={historyEntry?.selfReportedConditions
                      .map((condition) =>
                        convertEnumValueToReadableString(condition, " "),
                      )
                      .join(", ")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Occupation</TableCell>
                <TableCell>
                  <Value text={historyEntry?.occupation} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default MedicalDetailsHistoryEntryDialog;
