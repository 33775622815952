import { Value } from "@curaleaf-international/components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { PatientGpDetailsHistory } from "src/models";

interface IProps {
  onClose: () => void;
  open: boolean;
  historyEntry: PatientGpDetailsHistory | null;
}

const GpDetailsHistoryEntryDialog = ({
  onClose,
  open,
  historyEntry,
}: IProps) => {
  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle>GP Details History Entry</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>
                  <Value dateTime={historyEntry?.timestamp} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Changed By</TableCell>
                <TableCell>
                  {historyEntry?.patientId ? (
                    "Patient"
                  ) : historyEntry?.staffId ? (
                    <Link
                      component={WLink}
                      href={`/staff/${historyEntry.staffId}/`}
                    >
                      {historyEntry.staffEmail}
                    </Link>
                  ) : (
                    "System"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reason</TableCell>
                <TableCell>
                  <Value text={historyEntry?.reason} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GP Name</TableCell>
                <TableCell>
                  <Value text={historyEntry?.name} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GP Email</TableCell>
                <TableCell>
                  <Value text={historyEntry?.email} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GP Phone Number</TableCell>
                <TableCell>
                  <Value text={historyEntry?.phoneNumber} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GP Address</TableCell>
                <TableCell>
                  <Value
                    text={
                      historyEntry?.addressLines &&
                      historyEntry.addressLines.join(", ")
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GP Postcode</TableCell>
                <TableCell>
                  <Value text={historyEntry?.postcode} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default GpDetailsHistoryEntryDialog;
