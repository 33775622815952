import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";

import HistorySummaryTable from "src/components/HistorySummaryTable";
import MedicalDetailsHistoryEntryDialog from "src/components/MedicalDetailsHistoryEntryDialog";
import { usePatientMedicalDetailsQuery } from "src/queries";
import { usePatientMedicalDetailsHistoryQuery } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  patientId: string;
}

const PatientMedicalDetails = ({ patientId }: IProps) => {
  const { data: details } = usePatientMedicalDetailsQuery(patientId);
  const { data: detailsHistory } =
    usePatientMedicalDetailsHistoryQuery(patientId);
  const [open, setOpen] = useState(false);
  const [entryId, setEntryId] = useState(0);

  return (
    <>
      <MedicalDetailsHistoryEntryDialog
        onClose={() => setOpen(false)}
        open={open}
        historyEntry={
          detailsHistory?.find((entry) => entry.id === entryId) ?? null
        }
      />
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader title="Medical Details" />
        <Divider />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Alcohol Use</TableCell>
                <TableCell>
                  <Value text={details?.alcoholUse} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Self-Reported Current Medication</TableCell>
                <TableCell>
                  <Value text={details?.selfReportedCurrentMedication} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Self-Reported Treatment History</TableCell>
                <TableCell>
                  <Value text={details?.selfReportedTreatmentHistory} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reason(s) for Applying</TableCell>
                <TableCell>
                  <Value
                    text={details?.selfReportedConditions
                      .map((condition) =>
                        convertEnumValueToReadableString(condition, " "),
                      )
                      .join(", ")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Occupation</TableCell>
                <TableCell>
                  <Value text={details?.occupation} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card>
        <CardHeader title="Medical Details History" />
        <Divider />
        <HistorySummaryTable
          setOpen={setOpen}
          setEntryId={setEntryId}
          history={detailsHistory ?? []}
        />
      </Card>
    </>
  );
};

export default PatientMedicalDetails;
