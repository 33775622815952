import axios from "axios";

import {
  PatientGpDetailsHistory,
  newPatientGpDetailsHistory,
} from "src/models";
import { keys } from "src/queries/keys";
import { useQuery } from "src/query";

export const usePatientGpDetailsHistoryQuery = (patientId: string) =>
  useQuery<PatientGpDetailsHistory[]>({
    queryKey: keys.patientGpDetailsHistory(patientId),
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/patient-gp-details-history/patient/${patientId}/`,
        { signal },
      );
      return response.data.histories.map((data: unknown) =>
        newPatientGpDetailsHistory(data),
      );
    },
  });
