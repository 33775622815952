import axios from "axios";

import { PatientInfoHistory, newPatientInfoHistory } from "src/models";
import { keys } from "src/queries/keys";
import { useQuery } from "src/query";

export const usePatientInfoHistoryQuery = (patientId: string) =>
  useQuery<PatientInfoHistory[]>({
    queryKey: keys.patientInfoHistory(patientId),
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/patient-info-history/patient/${patientId}/`,
        { signal },
      );
      return response.data.histories.map((data: unknown) =>
        newPatientInfoHistory(data),
      );
    },
  });
