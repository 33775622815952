import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import ProductForm, { ValidatedType } from "src/components/ProductForm";
import { ProductAvailability } from "src/models";
import {
  useUpdateProductMutation,
  useProductQuery,
  useFormulaQuery,
} from "src/queries";

interface IProps {
  productId: string;
}

const EditProduct = ({ productId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: product } = useProductQuery(productId);
  const { data: formula } = useFormulaQuery(product?.formulaId);
  const { mutateAsync: editProduct } = useUpdateProductMutation(productId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await editProduct({
        ...data,
        availability: data.availability as ProductAvailability,
      });
      addToast("Product Updated", "success");
      setLocation(`/formulas/${product?.formulaId}/#products`);
    } catch {
      addToast("Try again", "error");
    }
  };

  const initialValues = useMemo(
    () => ({
      availability: product?.availability ?? ("" as const),
      quantity: product?.quantity ?? "",
      price: product?.price ?? "",
    }),
    [product],
  );

  return (
    <>
      <Title
        title="Edit Product"
        breadcrumbs={[
          {
            label: "Formulas",
            to: `/formulas/`,
          },
          {
            label: `${formula?.internalName}`,
            to: `/formulas/${formula?.id}/#products`,
          },
          { label: "Edit Product" },
        ]}
      />
      <Card>
        <ProductForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          label="Edit Product"
          formulaId={product?.formulaId}
          disabled={product === undefined || formula === undefined}
        />
      </Card>
    </>
  );
};

export default EditProduct;
