import { Page, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Tab from "@mui/material/Tab";
import { useState } from "react";

import ClinicalReviewActions from "src/components/ClinicalReviewActions";
import ClinicalReviewDetails from "src/pages/ClinicalReview/ClinicalReviewDetails";
import ClinicalReviewStateHistory from "src/pages/ClinicalReview/ClinicalReviewStateHistory";
import { useClinicalReviewQuery } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

type TabState = "details" | "history";

interface IProps {
  clinicalReviewId: string;
}

const ClinicalReview = ({ clinicalReviewId }: IProps) => {
  const { data: review } = useClinicalReviewQuery(clinicalReviewId);
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <ClinicalReviewActions clinicalReview={review} />
      </Menu>
      <TabContext value={tab}>
        <Page
          actions={
            <Button
              endIcon={<KeyboardArrowDownIcon />}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              variant="contained"
            >
              Actions
            </Button>
          }
          breadcrumbs={[
            { to: "/clinical-reviews/", label: "Clinical Reviews" },
          ]}
          tabs={
            <TabList
              aria-label="Clinical Review menu"
              onChange={(_, value: string) => setTab(value as TabState)}
            >
              <Tab label="Details" value="details" />
              <Tab label="History" value="history" />
            </TabList>
          }
          title={`${review?.patientName ?? ""} - ${review !== undefined ? convertEnumValueToReadableString(review?.type, " ") : ""}`}
        >
          <TabPanel value="details">
            <ClinicalReviewDetails clinicalReviewId={clinicalReviewId} />
          </TabPanel>
          <TabPanel value="history">
            <ClinicalReviewStateHistory clinicalReviewId={clinicalReviewId} />
          </TabPanel>
        </Page>
      </TabContext>
    </>
  );
};

export default ClinicalReview;
