import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { PartnerUser, PartnerUserRole, newPartnerUser } from "src/models";
import { useMutation, useQuery } from "src/query";

export type PartnerUserMapping = { [key: string]: PartnerUser };

export const usePartnerUsersQuery = () =>
  useQuery<PartnerUserMapping>({
    queryKey: ["partnerUsers"],
    queryFn: async () => {
      const response = await axios.get("/v1/partner-users/");
      return response.data.partnerUsers.reduce(
        (accum: PartnerUserMapping, json: any) => {
          const user = newPartnerUser(json);
          accum[user.id] = user;
          return accum;
        },
        {},
      );
    },
  });

export const usePartnerUserQuery = (id: number | undefined) => {
  return useQuery<PartnerUser>({
    queryKey: ["partnerUsers", id],
    queryFn: async () => {
      const response = await axios.get(`/v1/partner-users/${id}/`);
      return newPartnerUser(response.data);
    },
    enabled: id !== undefined,
  });
};

interface PartnerUserData {
  email: string;
  roles: PartnerUserRole[];
}

export const useCreatePartnerUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PartnerUserData) =>
      await axios.post("/v1/partner-users/", data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["partnerUsers"] }),
  });
};

interface PartnerUserDisabledData {
  disabled: boolean;
}

export const useToggleDisabledPartnerUserMutation = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PartnerUserDisabledData) =>
      await axios.put(`/v1/partner-users/${id}/`, data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["partnerUsers"] }),
  });
};
