import { SkeletonRow, sortByKey } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";

import { SubscriptionPayment } from "src/models";
import SubscriptionPaymentRow from "src/pages/Subscription/SubscriptionPaymentRow";
import { useSubscriptionPaymentsQuery } from "src/queries";

interface IProps {
  subscriptionId: string;
}

type Direction = "asc" | "desc";
type OrderableProperties = "action" | "state" | "timestamp";

const SubscriptionPayments = ({ subscriptionId }: IProps) => {
  const { data: paymentsData } = useSubscriptionPaymentsQuery(subscriptionId);
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("timestamp");

  const sortKey = (payments: SubscriptionPayment) => [payments[orderBy]];

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filterPayments = (payments: SubscriptionPayment[]) => {
    return payments.sort(sortByKey(sortKey, order));
  };

  return (
    <>
      <Card>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "timestamp"}
                    direction={order}
                    onClick={onSortClick("timestamp")}
                  >
                    Timestamp
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "action"}
                    direction={order}
                    onClick={onSortClick("action")}
                  >
                    Action
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "state"}
                    direction={order}
                    onClick={onSortClick("state")}
                  >
                    State
                  </TableSortLabel>
                </TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Staff</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentsData?.payments === undefined ? (
                <SkeletonRow cols={8} />
              ) : paymentsData.payments[0] === undefined ? (
                <TableRow>
                  <TableCell colSpan={8}>No payments found.</TableCell>
                </TableRow>
              ) : (
                filterPayments(paymentsData.payments).map(
                  (payment: SubscriptionPayment) => (
                    <SubscriptionPaymentRow
                      key={payment.id}
                      payment={payment}
                    />
                  ),
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default SubscriptionPayments;
