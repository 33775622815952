import * as z from "zod";

const outOfStockListingSchema = z.object({
  id: z.string(),
  alternativeFirestoreFormulaIds: z.array(z.string()),
  endedOn: z.coerce.date().nullable(),
  endedBy: z.number().int().positive().nullable(),
  endedByEmail: z.string().nullable(),
  createdOn: z.coerce.date(),
  createdBy: z.number().int().positive(),
  createdByEmail: z.string(),
  firestoreFormulaId: z.string(),
  productName: z.string(),
  patientInformation: z.string().nullable(),
  internalInformation: z.string().nullable(),
});

export type OutOfStockListing = z.infer<typeof outOfStockListingSchema>;

export const newOutOfStockListing = (data: unknown): OutOfStockListing =>
  outOfStockListingSchema.parse(data);

const outOfStockListingHistorySchema = z.object({
  id: z.number().int(),
  listingId: z.string(),
  alternativeFirestoreFormulaIds: z.array(z.string()),
  created: z.coerce.date(),
  createdByEmail: z.string(),
});

export type OutOfStockListingHistory = z.infer<
  typeof outOfStockListingHistorySchema
>;

export const newOutOfStockListingHistory = (
  data: unknown,
): OutOfStockListingHistory => outOfStockListingHistorySchema.parse(data);
