import { formatDate, SkeletonRow } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";

import { OutOfStockListingHistory } from "src/models";
import { useProductsQuery } from "src/queries";

interface IProps {
  current: boolean;
  record: OutOfStockListingHistory;
}

const OutOfStockListingHistoryTableRow = ({ record, current }: IProps) => {
  const { data: products } = useProductsQuery(false);
  const [open, setOpen] = useState(false);

  const rows = products
    ?.filter((product) =>
      record.alternativeFirestoreFormulaIds.includes(product.id),
    )
    .map((product, index) => (
      <TableRow key={index}>
        <TableCell>{product.name}</TableCell>
      </TableRow>
    )) ?? <SkeletonRow cols={3} />;

  return (
    <>
      <TableRow key={record.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{`${formatDate(record.created)} by ${
          record.createdByEmail
        } `}</TableCell>
        <TableCell>{current ? "CURRENT" : "ENDED"}</TableCell>
        <TableCell>{record.alternativeFirestoreFormulaIds.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{rows}</TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OutOfStockListingHistoryTableRow;
