import * as z from "zod";

export enum CommunicationPreferences {
  EMAIL = "EMAIL",
  TEXT = "TEXT",
  PHONE = "PHONE",
}

export enum Sex {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

const patientInfoSchema = z.object({
  id: z.string().uuid(),
  patientId: z.string().uuid(),
  nhsNumber: z.string().nullable(),
  communicationPreferences: z.array(z.nativeEnum(CommunicationPreferences)),
  legalName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    ),
  preferredName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    )
    .nullable(),
  pronouns: z.string().nullable(),
  dob: z.coerce.date(),
  email: z.string(),
  phoneNumber: z.string(),
  sexAssignedAtBirth: z.nativeEnum(Sex),
  addressLines: z.array(z.string()),
  postcode: z.string(),
  carerLegalName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    )
    .nullable(),
  carerEmail: z.string().nullable(),
  carerPhoneNumber: z.string().nullable(),
});

export type PatientInfo = z.infer<typeof patientInfoSchema>;

export const newPatientInfo = (data: unknown): PatientInfo =>
  patientInfoSchema.parse(data);
