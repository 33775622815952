import axios from "axios";
import { formatISO, isValid } from "date-fns";

import { newActivity, Activity } from "src/models";
import { ActivityType } from "src/pages/Tools/Activities";
import { useQuery } from "src/query";

export const useActivitiesQuery = (
  activity?: ActivityType | null,
  before?: Date | null,
  after?: Date | null,
  staffId?: number | null,
  partnerUserId?: number | null,
) =>
  useQuery<Activity[]>({
    queryKey: [
      "activities",
      activity?.toString() ?? "undefined",
      after?.toString() ?? "undefined",
      before?.toString() ?? "undefined",
      staffId?.toString() ?? "undefined",
      partnerUserId?.toString() ?? "undefined",
    ],
    queryFn: async () => {
      const params = {
        activity,
        after:
          after && isValid(after)
            ? formatISO(after, { representation: "date" })
            : undefined,
        before:
          before && isValid(before)
            ? formatISO(before, { representation: "date" })
            : undefined,
        staffId,
        partnerUserId,
      };
      const response = await axios.get("/v1/activities/", { params });
      return response.data.activities.map((data: unknown) => newActivity(data));
    },
  });
