import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import {
  PartnerUserRole,
  PartnerUserRoles,
  newPartnerUserRoles,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const usePartnerUserRolesQuery = (id: number | undefined) => {
  return useQuery<PartnerUserRoles[]>({
    queryKey: ["partnerUserRoles", id],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/partner-user-roles/partner-user/${id}/`,
      );
      return response.data.roles.map((data: unknown) =>
        newPartnerUserRoles(data),
      );
    },
    enabled: id !== undefined,
  });
};

export interface PartnerUserRolesData {
  roles: PartnerUserRole[];
  reason: string;
}

export const useUpdatePartnerUserRolesMutation = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: PartnerUserRolesData) =>
      await axios.put(`/v1/partner-user-roles/partner-user/${id}/`, data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["partnerUserRoles", id] }),
  });
};
