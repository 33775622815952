import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";

import HistorySummaryTable from "src/components/HistorySummaryTable";
import PatientInfoHistoryEntryDialog from "src/components/PatientInfoHistoryEntryDialog";
import { usePatientInfoQuery } from "src/queries";
import { usePatientInfoHistoryQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const PatientInfo = ({ patientId }: IProps) => {
  const { data: details } = usePatientInfoQuery(patientId);
  const { data: detailsHistory } = usePatientInfoHistoryQuery(patientId);
  const [open, setOpen] = useState(false);
  const [entryId, setEntryId] = useState(0);

  return (
    <>
      <PatientInfoHistoryEntryDialog
        onClose={() => setOpen(false)}
        open={open}
        historyEntry={
          detailsHistory?.find((entry) => entry.id === entryId) ?? null
        }
      />
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader title="Patient Info" />
        <Divider />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Legal Name</TableCell>
                <TableCell>
                  <Value text={details?.legalName} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Preferred Name</TableCell>
                <TableCell>
                  <Value text={details?.preferredName} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pronouns</TableCell>
                <TableCell>
                  <Value text={details?.pronouns} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>DOB</TableCell>
                <TableCell>
                  <Value date={details?.dob} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>NHS Number</TableCell>
                <TableCell>
                  <Value text={details?.nhsNumber} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>
                  <Value text={details?.email} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone Number</TableCell>
                <TableCell>
                  <Value text={details?.phoneNumber} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>
                  <Value text={details?.addressLines.join(", ")} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Postcode</TableCell>
                <TableCell>
                  <Value text={details?.postcode} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carer Legal Name</TableCell>
                <TableCell>
                  <Value text={details?.carerLegalName} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carer Email</TableCell>
                <TableCell>
                  <Value text={details?.carerEmail} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carer Phone Number</TableCell>
                <TableCell>
                  <Value text={details?.carerPhoneNumber} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Communication Preferences</TableCell>
                <TableCell>
                  <Value text={details?.communicationPreferences.join(", ")} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card>
        <CardHeader title="Patient Info History" />
        <Divider />
        <HistorySummaryTable
          setOpen={setOpen}
          setEntryId={setEntryId}
          history={detailsHistory ?? []}
        />
      </Card>
    </>
  );
};

export default PatientInfo;
