import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext } from "react";
import { useLocation } from "wouter";

import ProductForm, { ValidatedType } from "src/components/ProductForm";
import { ProductAvailability } from "src/models";
import { useFormulaQuery, useCreateProductMutation } from "src/queries";

interface IProps {
  formulaId: string;
}

const CreateProduct = ({ formulaId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: createProduct } = useCreateProductMutation();

  const onSubmit = async (data: ValidatedType) => {
    try {
      await createProduct({
        ...data,
        availability: data.availability as ProductAvailability,
        formulaId,
      });
      addToast("Product Created", "success");
      setLocation(`/formulas/${formulaId}/#products`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title
        title="Create Product"
        breadcrumbs={[
          {
            label: "Formulas",
            to: `/formulas/`,
          },
          {
            label: `${formula?.internalName}`,
            to: `/formulas/${formula?.id}/#products`,
          },
          { label: "Edit Product" },
        ]}
      />
      <Card>
        <ProductForm
          initialValues={{
            availability: "",
            quantity: "",
            price: "",
          }}
          onSubmit={onSubmit}
          label="Create Product"
          formulaId={formulaId}
          disabled={formula === undefined}
        />
      </Card>
    </>
  );
};

export default CreateProduct;
