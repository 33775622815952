import * as z from "zod";

export enum EvidenceType {
  NCRS = "NCRS",
  PATIENT_UPLOAD = "PATIENT_UPLOAD",
  SCR_FROM_GP = "SCR_FROM_GP",
}

const patientApplicationDetailsSchema = z.object({
  id: z.string().uuid(),
  patientId: z.string().uuid(),
  affiliateCode: z.string().nullable(),
  howHearAboutUs: z.string().nullable(),
  transferFrom: z.string().nullable(),
  evidenceUsedInReview: z.array(z.nativeEnum(EvidenceType)),
});

export type PatientApplicationDetails = z.infer<
  typeof patientApplicationDetailsSchema
>;

export const newPatientApplicationDetails = (
  data: unknown,
): PatientApplicationDetails => patientApplicationDetailsSchema.parse(data);
