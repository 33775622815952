import axios from "axios";

import { FirestoreFormula, newFirestoreFormula } from "src/models";
import { useQuery } from "src/query";

export const useProductsQuery = (includeOtherMeds: boolean) => {
  return useQuery<FirestoreFormula[]>({
    queryKey: ["products", includeOtherMeds],
    queryFn: async () => {
      const params = { includeOtherMeds };
      const response = await axios.get("/v1/firestore-formulas/", { params });
      return response.data.products.map((data: unknown) => {
        return newFirestoreFormula(data);
      });
    },
  });
};
