import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";

import GpDetailsHistoryEntryDialog from "src/components/GpDetailsHistoryEntryDialog";
import HistorySummaryTable from "src/components/HistorySummaryTable";
import { usePatientGpDetailsQuery } from "src/queries";
import { usePatientGpDetailsHistoryQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const PatientGpDetails = ({ patientId }: IProps) => {
  const { data: details } = usePatientGpDetailsQuery(patientId);
  const { data: detailsHistory } = usePatientGpDetailsHistoryQuery(patientId);
  const [open, setOpen] = useState(false);
  const [entryId, setEntryId] = useState(0);

  return (
    <>
      <GpDetailsHistoryEntryDialog
        onClose={() => setOpen(false)}
        open={open}
        historyEntry={
          detailsHistory?.find((entry) => entry.id === entryId) ?? null
        }
      />
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader title="GP Details" />
        <Divider />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>GP Name</TableCell>
                <TableCell>
                  <Value text={details?.name} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GP Email</TableCell>
                <TableCell>
                  <Value text={details?.email} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GP Phone Number</TableCell>
                <TableCell>
                  <Value text={details?.phoneNumber} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GP Address</TableCell>
                <TableCell>
                  <Value text={details?.addressLines.join(", ")} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GP Postcode</TableCell>
                <TableCell>
                  <Value text={details?.postcode} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card>
        <CardHeader title="GP Details History" />
        <Divider />
        <HistorySummaryTable
          setOpen={setOpen}
          setEntryId={setEntryId}
          history={detailsHistory ?? []}
        />
      </Card>
    </>
  );
};

export default PatientGpDetails;
