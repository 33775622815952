import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ProductTableRow from "src/pages/Formula/ProductTableRow";
import { useProductsForFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const FormulaProductsTable = ({ formulaId }: IProps) => {
  const { data: products } = useProductsForFormulaQuery(formulaId);
  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Availability</TableCell>
              <TableCell>Curaleaf Product</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products?.length === 0 ? (
              <TableCell colSpan={4}>No products found.</TableCell>
            ) : (
              (products?.map((product) => (
                <ProductTableRow key={product.id} product={product} />
              )) ?? <SkeletonRow cols={4} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default FormulaProductsTable;
