import * as z from "zod";

const productGroupSchema = z.object({
  id: z.string(),
  createdByEmail: z.string(),
  createdOn: z.coerce.date(),
  discontinued: z.coerce.date().nullable(),
  discontinuedBy: z.number().nullable(),
  discontinuedByEmail: z.string().nullable(),
  discontinuedReason: z.string().nullable(),
  lastUpdated: z.coerce.date().nullable(),
  lastUpdatedBy: z.number().nullable(),
  lastUpdatedByEmail: z.string().nullable(),
  name: z.string(),
  prescriptionCount: z.number(),
  firestoreFormulaIds: z.array(z.string()),
  productType: z.string(),
});

export type ProductGroup = z.infer<typeof productGroupSchema>;

export const newProductGroup = (data: unknown): ProductGroup =>
  productGroupSchema.parse(data);

const productGroupHistorySchema = z.object({
  id: z.number().int(),
  groupId: z.string(),
  firestoreFormulaIds: z.array(z.string()),
  created: z.coerce.date(),
  createdBy: z.number(),
  createdByEmail: z.string(),
});

export type ProductGroupHistory = z.infer<typeof productGroupHistorySchema>;

export const newProductGroupHistory = (data: unknown): ProductGroupHistory =>
  productGroupHistorySchema.parse(data);
