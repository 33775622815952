import * as z from "zod";

export const prescriptionSchema = z.object({
  id: z.string(),
  issueDate: z.coerce.date(),
  patientId: z.string(),
  prescriberEmail: z.string(),
  prescriberId: z.string().uuid(),
  prescriberSignaturePresent: z.boolean(),
  firestoreFormulaId: z.string(),
  productName: z.string(),
  quantity: z.number(),
  quantityUnits: z.string(),
  titration: z.string(),
});

export type Prescription = z.infer<typeof prescriptionSchema>;
export const newPrescription = (data: unknown): Prescription =>
  prescriptionSchema.parse(data);
