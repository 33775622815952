import * as z from "zod";

import { ReasonForApplication } from "src/models";

const patientMedicalDetailsHistorySchema = z.object({
  id: z.number().int().positive(),
  patientMedicalDetailsId: z.string().uuid(),
  patientId: z.string().uuid().nullable(),
  alcoholUse: z.string().nullable(),
  selfReportedCurrentMedication: z.string(),
  selfReportedTreatmentHistory: z.string(),
  selfReportedConditions: z.array(z.nativeEnum(ReasonForApplication)),
  occupation: z.string(),
  reason: z.string().nullable(),
  staffId: z.number().int().positive().nullable(),
  staffEmail: z.string().email().nullable(),
  timestamp: z.coerce.date(),
});

export type PatientMedicalDetailsHistory = z.infer<
  typeof patientMedicalDetailsHistorySchema
>;

export const newPatientMedicalDetailsHistory = (
  data: unknown,
): PatientMedicalDetailsHistory =>
  patientMedicalDetailsHistorySchema.parse(data);
