import { useMutation } from "@curaleaf-international/components";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { PartnerUserSession, newPartnerUserSession } from "src/models";
import { useQuery } from "src/query";

export const usePartnerUserSessionsQuery = (
  partnerUserId: number | undefined,
) =>
  useQuery<PartnerUserSession[]>({
    queryKey: ["partnerUserSessions", partnerUserId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/partner-user-sessions/partner-user/${partnerUserId}/`,
      );
      return response.data.sessions.map((data: unknown) =>
        newPartnerUserSession(data),
      );
    },
  });

export const useInvalidatePartnerUserSessionMutation = (sessionId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.delete(`/v1/partner-user-sessions/${sessionId}/`),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["partnerUserSessions"] }),
  });
};
