import * as z from "zod";

import { ReasonForApplication } from "src/models";

const patientMedicalDetailsSchema = z.object({
  id: z.string().uuid(),
  patientId: z.string().uuid(),
  alcoholUse: z.string().nullable(),
  selfReportedCurrentMedication: z.string(),
  selfReportedTreatmentHistory: z.string(),
  selfReportedConditions: z.array(z.nativeEnum(ReasonForApplication)),
  occupation: z.string(),
});

export type PatientMedicalDetails = z.infer<typeof patientMedicalDetailsSchema>;

export const newPatientMedicalDetails = (
  data: unknown,
): PatientMedicalDetails => patientMedicalDetailsSchema.parse(data);
