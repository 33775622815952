import * as z from "zod";

const patientGpDetailsHistorySchema = z.object({
  id: z.number().int().positive(),
  patientGpDetailsId: z.string().uuid(),
  patientId: z.string().uuid().nullable(),
  name: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    )
    .nullable(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  addressLines: z.array(z.string()),
  postcode: z.string().nullable(),
  reason: z.string().nullable(),
  staffId: z.number().int().positive().nullable(),
  staffEmail: z.string().email().nullable(),
  timestamp: z.coerce.date(),
});

export type PatientGpDetailsHistory = z.infer<
  typeof patientGpDetailsHistorySchema
>;

export const newPatientGpDetailsHistory = (
  data: unknown,
): PatientGpDetailsHistory => patientGpDetailsHistorySchema.parse(data);
