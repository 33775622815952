import * as z from "zod";

export enum Role {
  CONSULTANT = "CONSULTANT",
  CUSTOMER_SERVICE = "CUSTOMER_SERVICE",
  CUSTOMER_SERVICE_LEAD = "CUSTOMER_SERVICE_LEAD",
  FINANCE = "FINANCE",
  GENERAL_PRACTITIONER = "GENERAL_PRACTITIONER",
  HEAD_OF_CLINIC = "HEAD_OF_CLINIC",
  DEVELOPER = "DEVELOPER",
  MEDICAL_SECRETARY = "MEDICAL_SECRETARY",
  MEDICAL_SECRETARY_LEAD = "MEDICAL_SECRETARY_LEAD",
  ONBOARDING = "ONBOARDING",
  ONBOARDING_LEAD = "ONBOARDING_LEAD",
  PHARMACIST = "PHARMACIST",
  PHARMACIST_TECHNICIAN = "PHARMACIST_TECHNICIAN",
  REPORTER = "REPORTER",
  STAFF_MANAGER = "STAFF_MANAGER",
}

const staffSchema = z.object({
  id: z.coerce.number().int().positive(),
  activeSessions: z.number().int(),
  created: z.coerce.date(),
  disabled: z.coerce.date().nullable(),
  email: z.string().email(),
  firebaseUid: z.string().nullable(),
  firebaseEmail: z.string().nullable(),
  lastLogin: z.coerce.date().nullable(),
  microsoftId: z.string(),
  roles: z.array(z.nativeEnum(Role)),
});

export type Staff = z.infer<typeof staffSchema>;

export const newStaff = (data: unknown): Staff => staffSchema.parse(data);

const staffRolesSchema = z.object({
  id: z.coerce.number().int().positive(),
  staffId: z.coerce.number().int().positive(),
  activeFrom: z.coerce.date(),
  expires: z.coerce.date(),
  roles: z.array(z.nativeEnum(Role)),
  grantedBy: z.coerce.number().int().positive(),
  grantedByEmail: z.string(),
  reason: z.string(),
});

export type StaffRoles = z.infer<typeof staffRolesSchema>;

export const newStaffRoles = (data: unknown): StaffRoles =>
  staffRolesSchema.parse(data);
