import { Page, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link } from "wouter";

import RequiresRoles from "src/components/RequiresRoles";
import { Role } from "src/models";
import PartnerUserActivities from "src/pages/PartnerUser/PartnerUserActivities";
import PartnerUserDetails from "src/pages/PartnerUser/PartnerUserDetails";
import PartnerUserRoleHistory from "src/pages/PartnerUser/PartnerUserRoleHistory";
import PartnerUserSessions from "src/pages/PartnerUser/PartnerUserSessions";
import { usePartnerUserQuery } from "src/queries";

type TabState = "details" | "roleHistory" | "activity" | "sessions";

interface IProps {
  partnerUserId: number;
}

const PartnerUser = ({ partnerUserId }: IProps) => {
  const { data: partnerUser } = usePartnerUserQuery(partnerUserId);
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <RequiresRoles roles={[Role.STAFF_MANAGER]}>
        <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
          <MenuItem
            component={Link}
            to={`/partner-users/${partnerUserId}/edit-roles/`}
          >
            Edit
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/partner-users/${partnerUserId}/disabled/`}
          >
            {partnerUser?.disabled === null ? "Disable" : "Enable"}
          </MenuItem>
        </Menu>
      </RequiresRoles>
      <TabContext value={tab}>
        <Page
          actions={
            <Button
              endIcon={<KeyboardArrowDownIcon />}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              variant="contained"
            >
              Actions
            </Button>
          }
          disablePadding={true}
          breadcrumbs={[
            { to: "/partner-users/", label: "Partner User" },
            { label: `${partnerUser?.email ?? ""}` },
          ]}
          title={partnerUser?.email ?? ""}
          tabs={
            <TabList
              aria-label="Partner User menu"
              onChange={(_, value: string) => setTab(value as TabState)}
            >
              <Tab label="Details" value="details" />
              <Tab label="Role History" value="roleHistory" />
              <Tab label="Sessions" value="sessions" />
              <Tab label="Activities" value="activities" />
            </TabList>
          }
        >
          <Card>
            <TabPanel value={"details"}>
              <PartnerUserDetails partnerUserId={partnerUserId} />
            </TabPanel>

            <TabPanel value={"roleHistory"}>
              <PartnerUserRoleHistory partnerUserId={partnerUserId} />
            </TabPanel>
            <TabPanel value={"sessions"}>
              <PartnerUserSessions partnerUserId={partnerUserId} />
            </TabPanel>
            <TabPanel value={"activities"}>
              <PartnerUserActivities partnerUserId={partnerUserId} />
            </TabPanel>
          </Card>
        </Page>
      </TabContext>
    </>
  );
};

export default PartnerUser;
