import {
  useMutation as useCuraMutation,
  useQuery as useCuraQuery,
} from "@curaleaf-international/components";
import type { DefaultError, QueryClient, QueryKey } from "@tanstack/query-core";
import {
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { keys, relatedKeys } from "src/queries/keys";

export function useQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  queryClient?: QueryClient,
): UseQueryResult<TData, TError> {
  const { unauthenticate } = useContext(AuthContext);

  return useCuraQuery<TQueryFnData, TError, TData, TQueryKey>(
    options,
    queryClient,
    unauthenticate,
  );
}

export function useMutation<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
  queryClient?: QueryClient,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { unauthenticate } = useContext(AuthContext);

  return useCuraMutation<TData, TError, TVariables, TContext>(
    options,
    queryClient,
    unauthenticate,
  );
}

export const useQueryInvalidation = (key: keyof typeof keys) => {
  const queryClient = useQueryClient();
  return async () => {
    await Promise.all(
      relatedKeys[key].map((relatedKey) =>
        queryClient.invalidateQueries({ queryKey: relatedKey }),
      ),
    );
  };
};
